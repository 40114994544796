import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

// Shared
import { SharedModule } from './shared/shared/shared.module';

// Auth Gaurd
import {
  CanAuthActivate,
  CanLoginActivate,
  CanViewActivate,
} from './common/service/TS-files/auth.gaurd';

// Component
import { MainComponent } from './modules/main/main.component';
import { LoginComponent } from './public/login/login.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { SetPasswordComponent } from './public/set-password/set-password.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    canActivate: [CanLoginActivate],
    component: LoginComponent,
    pathMatch: 'full',
  },
  {
    path: 'forgot-password',
    canActivate: [CanLoginActivate],
    component: ForgotPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'reset-password',
    canActivate: [CanLoginActivate],
    component: ResetPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: 'set-password',
    canActivate: [CanLoginActivate],
    component: SetPasswordComponent,
    pathMatch: 'full',
  },
  {
    path: '',
    canActivate: [CanAuthActivate],
    component: MainComponent,
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule) },
      {
        path: 'users',
        canActivate: [CanViewActivate],
        data: { role: 'user' },
        loadChildren: () => import('./modules/user-management/users/users.module').then((m) => m.UsersModule),
      },
      {
        path: 'admin-users',
        canActivate: [CanViewActivate],
        data: { role: 'adminUser' },
        loadChildren: () => import('./modules/user-management/admin-users/admin-users.module').then((m) => m.AdminUsersModule),
      },
      {
        path: 'drivers',
        canActivate: [CanViewActivate],
        data: { role: 'driver' },
        loadChildren: () => import('./modules/user-management/drivers/drivers.module').then((m) => m.DriversModule),
      },
      {
        path: 'vendors',
        canActivate: [CanViewActivate],
        data: { role: 'vendor' },
        loadChildren: () => import('./modules/user-management/vendors/vendors.module').then((m) => m.VendorsModule),
      },
      {
        path: 'agents',
        // canActivate: [CanViewActivate],
        data: { role: 'agent' },
        loadChildren: () => import('./modules/user-management/agent/agent.module').then((m) => m.AgentUsersModule),
      },
      {
        path: 'bulk-uploads',
        canActivate: [CanViewActivate],
        data: { role: 'products', type: 'bulk-uploads' },
        loadChildren: () => import('./modules/product-catalog/bulk-uploads/bulk-uploads.module').then((m) => m.BulkUploadsModule),
      },
      {
        path: 'home',
        loadChildren: () => import('./modules/content-management/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'cms',
        loadChildren: () => import('./modules/content-management/cms/cms.module').then((m) => m.CmsModule),
      },
      {
        path: 'email-template',
        loadChildren: () => import('./modules/email-management/email-templates/email-templates.module')
          .then((m) => m.EmailTemplatesModule),
      },
      {
        path: 'smtp-settings',
        loadChildren: () => import('./modules/email-management/smtp-settings/smtp-settings.module')
          .then((m) => m.SmtpSettingsModule),
      },
      {
        path: 'country',
        loadChildren: () => import('./modules/master-management/country/country.module')
          .then((m) => m.CountryModule),
      },
      {
        path: 'state',
        loadChildren: () => import('./modules/master-management/state/state.module')
          .then((m) => m.StateModule),
      },
      {
        path: 'general',
        loadChildren: () => import('./modules/master-management/general/general.module')
          .then((m) => m.GeneralModule),
      },
      {
        path: 'measurements',
        loadChildren: () => import('./modules/master-management/measurement/measurement.module')
          .then((m) => m.MeasurementModule),
      },
      { path: 'socialMedia',
        loadChildren: () => import('./modules/settings/social-media/social-media.module')
          .then(m => m.SocialMediaModule) },
      {
        path: 'brands',
        canActivate: [CanViewActivate],
        data: { role: 'brands' },
        loadChildren: () => import('./modules/product-catalog/brand/brand.module')
          .then((m) => m.BrandModule),
      },
      {
        path: 'manage-roles',
        loadChildren: () => import('./modules/manage-roles/manage-roles.module')
          .then((m) => m.ManageRolesModule),
      },
      {
        path: 'global-settings',
        loadChildren: () => import('./modules/settings/global-settings/global-settings.module')
          .then((m) => m.GlobalSettingsModule),
      },
      {
        path: 'notification-settings',
        loadChildren: () => import('./modules/settings/notification-settings/notification.module')
          .then((m) => m.NotificationModule),
      },
      {
        path: 'notifications',
        loadChildren: () => import('./modules/settings/notifications/notification.module')
          .then((m) => m.NotificationListModule),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./modules/change-password/change-password.module')
          .then((m) => m.ChangePasswordModule),
      },
      {
        path: 'my-profile',
        loadChildren: () => import('./modules/my-profile/my-profile.module')
          .then((m) => m.MyProfileModule),
      },
      {
        path: 'products',
        canActivate: [CanViewActivate],
        data: { role: 'products' },
        loadChildren: () => import('./modules/product-catalog/products/products.module')
          .then((m) => m.ProductsModule),
      },
      {
        path: 'category',
        canActivate: [CanViewActivate],
        data: { role: 'category' },
        loadChildren: () => import('./modules/product-catalog/categories/categories.module')
          .then((m) => m.CategoriesModule),
      },
      {
        path: 's-category',
        canActivate: [CanViewActivate],
        data: { role: 'category' },
        loadChildren: () => import('./modules/product-catalog/sub-categories/sub-categories.module')
          .then((m) => m.SubCategoriesModule),
      },
      {
        path: 's-s-category',
        canActivate: [CanViewActivate],
        data: { role: 'category' },
        loadChildren: () => import('./modules/product-catalog/sub-sub-categories/sub-sub-categories.module')
          .then((m) => m.SubSubCategoriesModule),
      },
      {
        path: 'orders',
        canActivate: [CanViewActivate],
        data: { role: 'orders' },
        loadChildren: () => import('./modules/sales/orders/orders.module')
          .then((m) => m.OrdersModule),
      },
      {
        path: 'transactions',
        canActivate: [CanViewActivate],
        data: { role: 'orders' },
        loadChildren: () => import('./modules/sales/transactions/transactions.module')
          .then((m) => m.TransactionsModule),
      },
      {
        path: 'returns',
        canActivate: [CanViewActivate],
        data: { role: 'orders' },
        loadChildren: () => import('./modules/sales/returns/returns.module')
          .then((m) => m.ReturnsModule),
      },
      {
        path: 'shipments',
        canActivate: [CanViewActivate],
        data: { role: 'orders' },
        loadChildren: () => import('./modules/sales/shipments/shipments.module')
          .then((m) => m.ShipmentsModule),
      },
      {
        path: 'ratings',
        loadChildren: () => import('./modules/review-rating/review-rating.module')
          .then((m) => m.ReviewModule),
      },
      {
        path: 'manage-promocodes',
        loadChildren: () => import('./modules/manage-promocode/manage-promocode.module')
          .then((m) => m.PromocodeModule),
      },
      {
        path: 'payouts',
        loadChildren: () => import('./modules/manage-payouts/manage-payouts.module')
          .then((m) => m.PayoutsModule),
      },
      {
        path: 'payAgent',
        loadChildren: () => import('./modules/payout-agent/payout-agent.module')
          .then((m) => m.PayoutsAgentModule),
      },
      {
        path: 'driverSalaries',
        loadChildren: () => import('./modules/payout-driver/payout-driver.module')
          .then((m) => m.PayoutsDriversModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('./modules/faq/faq.module')
          .then((m) => m.FaqModule),
      },
      {
        path: 'ticket-listing',
        loadChildren: () => import('./modules/ticket-listing/ticket.module')
          .then((m) => m.TicketModule),
      },
      { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  declarations: [
    LoginComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    SetPasswordComponent,
    MainComponent,
  ],
  imports: [SharedModule, CommonModule, FormsModule,ReactiveFormsModule, RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
  providers: [
    CanAuthActivate,
    CanLoginActivate,
    CanViewActivate,
  ],
})
export class AppRoutingModule { }

export const AppRoutingComponents = [
];
