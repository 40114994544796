<div class="assign-order-modal">
	<div class="modal-header">
		<h4 class="modal-title text-center w-100">Assign Order</h4>
		<button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
			<span aria-hidden="true">&times;</span>
		</button>
	</div>
	<div class="modal-body">
		<div class="row">
			<div class="col-md-12 mb-4">
				<div class="row align-items-center">
					<div class="col-md-7">
						<div class="form-group mb-0">
							<input type="text" class="form-control" id="search-text" aria-describedby="search-text" [(ngModel)]="searchText"
								placeholder="Search Name" (keyup)="checkDriver(searchText)" autofocus />
						</div>
					</div>
				</div>
			</div>
			<tabset>
				<tab heading="Agents" (selectTab)="onSelect($event)">
					<div class="col-md-12 mt-2">
						<div class="mb-4" *ngFor="let driver of driverList">
							<div class="row align-items-center justify-content-between">
								<div class="col-md-9">
									<div class="d-flex">
										<img width="45" height="45" class="rounded" [src]="driver.photo?driver.photo:'assets/images/NoProfile.png'" alt="no image" />
										<div class="user-details pl-3">
											<p class="pb-1">{{driver.fullName}}</p>
											<span>{{driver.countryCode}} - {{driver.mobileNo}}</span>
										</div>
									</div>
								</div>
								<div class="col-md-3 assign-btn">
									<button type="button" class="btn btn-outline-primary pull-right m-0" (click)=assignDriver(driver._id)>Assign</button>
								</div>
							</div>
						</div>
						<div class="p-5 text-center" *ngIf="!driverList?.length">No Records found.</div>
					</div>
				</tab>
				<tab heading="Drivers" (selectTab)="onSelect($event)">
					<div class="col-md-12 mt-2">
						<div class="mb-4" *ngFor="let driver of driverList">
							<div class="row align-items-center justify-content-between">
								<div class="col-md-9">
									<div class="d-flex">
										<img width="45" height="45" class="rounded" [src]="driver.photo?driver.photo:'assets/images/NoProfile.png'" alt="no image" />
										<div class="user-details pl-3">
											<p class="pb-1">{{driver.fullName}}</p>
											<span>{{driver.countryCode}} - {{driver.mobileNo}}</span>
										</div>
									</div>
								</div>
								<div class="col-md-3 assign-btn">
									<button type="button" class="btn btn-outline-primary pull-right m-0" (click)=assignDriver(driver._id)>Assign</button>
								</div>
							</div>
						</div>
						<div class="p-5 text-center" *ngIf="!driverList?.length">No Records found.</div>

					</div>
				</tab>
			</tabset>
		
		</div>
	</div>
</div>