<div class="authentic-page">
  <div class="back-img">
    <div class="container">
      <div class="authentic-wrapper forget-pass">
        <div class="row">
          <div class="col-md-6">
            <div class="login-container">
              <div class="login-section">
                <h3 class="pb-2 mb-5">{{passwordData.headerTitle}}</h3>
                <form class="example-form" #passwordForm="ngForm" (ngSubmit)="onSubmitPassword(passwordForm, password)"
                  content-type="application/x-www-form-urlencoded">
                  <div class='form-group row'>
                    <label htmlFor="New Password">New Password</label>
                    <div class="col-md-12" [ngClass]="((submitted || newPassword.touched) && newPassword.invalid)?'has-danger':''">
                      <input class="form-control" placeholder="New Password" type="password" name="password"
                        autocomplete="on" [(ngModel)]="password.password" [ngClass]="((submitted || newPassword.touched) && newPassword.invalid)?'has-danger':''"
                        pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$" value=""
                        id="password" required #newPassword="ngModel" />
                      <div class="error-block text-left"
                        *ngIf="((submitted || newPassword.touched) && newPassword.invalid)">
                        {{showErrorService.showError('newPassword', newPassword.errors)}}
                      </div>
                    </div>
                  </div>
                  <div class='form-group row'>
                    <label htmlFor="Confirm Password">Confirm Password</label>
                    <div class="col-md-12"  [ngClass]="((submitted || confirmpassword.touched || confirmpassword.dirty) && password.confirmpassword != password.password) || (submitted || confirmpassword.touched) && confirmpassword.invalid?'has-danger':''" >
                      <input class="form-control" placeholder="Confirm Password" type="password" name="confirmpassword"
                        [(ngModel)]="password.confirmpassword"
                        [ngClass]="((submitted || confirmpassword.touched || confirmpassword.dirty) && password.confirmpassword != password.password) || (submitted || confirmpassword.touched) && confirmpassword.invalid?'has-danger':''"  
                        autocomplete="off" id="confirmpassword" required #confirmpassword="ngModel" />
                      <div class="error-block text-left"
                        *ngIf="((submitted || confirmpassword.touched) && confirmpassword.invalid)">
                        Enter Confirm Password
                      </div>
                      <div class="form-control-feedback error-block"
                      *ngIf="((submitted || confirmpassword.touched || confirmpassword.dirty) && confirmpassword.valid && (password.confirmpassword != password.password))">
                      Password & Confirm Password must be same.
                    </div>
                    </div>
                  </div>
                  <div class='mt-5'>
                    <button class='btn btn-block m-0 btn-primary' type='submit'> {{passwordData.buttonTitle}}
                      <span><em aria-hidden="true" class="fa fa-arrow-right"></em></span>
                    </button>
                    
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-md-6 login-logo">
            <img src="assets/images/admin-forgot-password-banner.png" alt="no img" class="header-logo img-fluid" alt="Forget password img" title="Forget password" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>