import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import { messages } from '../../constants/errorMessagesData';

@Injectable()
export class ErrorMessages {
  public MSG = (messages as any);

  constructor() { }
  /*****************************************************************************************
  @PURPOSE      : To Show Error on Form validations
  @PARAMETERS   : field, error
  @RETURN       : message
  /*****************************************************************************************/
  getError(field, error) {
    let message = '';
    if (error) {
      if (error.required) {
        const required = this.MSG.ERROR.REQUIRED;
        switch (field) {
          case 'username':   {  message = required.Username;}break;
          case 'mobileNo':{  message = required.mobileNo;}break;
          case 'password':{  message = required.Password;}break;
          case 'smtpPassword':{  message = required.password;}break;
          case 'oldPassword':{  message = required.currentPassword;}break;
          case 'amount':{  message = required.Amount;}break;
          case 'mobile':{  message = required.mobile;}break;
          case 'newPassword':{  message = required.newPassword;}break;
          case 'confirmPassword':{  message = required.confirmPassword;}break;
          case 'firstName':{  message = required.firstName;}break;
          case 'lastName':{  message = required.lastName;}break;
          case 'phoneNo':{  message = required.PhoneNo;}break;
          case 'emailId':{  message = required.emailId;}break;
          case 'roleName':{  message = required.roleName;}break;
          case 'filterName':{  message = required.filterName;}break;
          case 'emailKey':{  message = required.emailKey;}break;
          case 'subject':{  message = required.subject;}break;
          case 'fromEmail':{  message = required.fromEmail;}break;
          case 'emailTemplateId':{  message = required.emailTemplateId;}break;
          case 'host':{  message = required.host;}break;
          case 'port':{  message = required.port;}break;
          case 'defaultFromEmail':{  message = required.defaultFromEmail;}break;
          case 'metaTitle':{  message = required.metaTitle;}break;
          case 'metaDescription':{  message = required.metaDescription;}break;
          case 'metaKeyword':{  message = required.metaKeyword;}break;
          case 'pageTitle':{  message = required.pageTitle;}break;
          case 'currency':{  message = required.currency;}break;
          case 'dateFormat':{  message = required.dateFormat;}break;
          case 'defaultAdminEmail':{  message = required.defaultAdminEmail;}break;
          case 'categoryName':{  message = required.categoryName;}break;
          case 'customUrl':{  message = required.customUrl;}break;
          case 'productName':{  message = required.productName;}break;
          case 'price':{  message = required.price;}break;
          case 'weight':{  message = required.weight;}break;
          case 'discount':{  message = required.discount;}break;
          case 'stockQuantity':{  message = required.stockQuantity;}break;
          case 'availability':{  message = required.availability;}break;
          case 'selectedCategoryName1':{  message = required.selectedCategoryName1;}break;
          case 'brandId':{  message = required.brandId;}break;
          case 'supplierId':{  message = required.supplierId;}break;
          case 'brandName':{  message = required.brandName;}break;
          case 'driverName':{  message = required.driverName;}break;
          case 'drivingLicenseNumber':{  message = required.drivingLicenseNumber;}break;
          case 'capital':{  message = required.capital;}break;
          case 'hasc':{  message = required.hasc;}break;
          case 'province':{  message = required.province;}break;
          case 'address':{  message = required.address;}break;
          case 'pageUrl':{  message = required.pageUrl;}break;
          case 'title':{  message = required.title;}break;
          case 'description':{  message = required.description;}break;
          case 'bannerUrl':{  message = required.bannerUrl;}break;
          case 'comment':{  message = required.comment;}break;
          case 'reason':{  message = required.reason;}break;
          case 'driver':{  message = required.driver;}break;
          case 'driverId':{  message = required.driverId;}break;
          case 'discountType':{  message = required.discountType;}break;
          case 'timezone':  {    message = required.timezone  }break;
          case 'timezoneInfo':  {    message = required.timezoneInfo  }break;
          case 'dateFormatInfo':  {    message = required.dateFormatInfo  }break;
          case 'timeFormatInfo':  {    message = required.timeFormatInfo  }break;
            case 'countryName':  {    message = required.countryName  }break;
            case 'countryCode':  {    message = required.countryCode  }break;
            case 'phoneCode':  {    message = required.phoneCode  }break;
            case 'addCurrency':  {    message = required.addCurrency  }break;
            case 'countryNameInfo':  {    message = required.countryNameInfo  }break;
          case 'validity':  {    message = required.validity  }break;
          case 'promoCode':  {    message = required.promoCode  }break;
          case 'minAmount':  {    message = required.minAmount  }break;
          case 'maxAmount':  {    message = required.maxAmount  }break;
          case 'vendorId':  {    message = required.vendorId  }break;
          case 'measurements':  {    message = required.measurements  }break;
          case 'measurementsType':  {    message = required.measurementsType  }break;
          case 'accountSid':  {    message = required.accountSid  }break;
          case 'offlineMessage':  {    message = required.offlineMessage  }break;
          case 'headerSnippet':  {    message = required.headerSnippet  }break;
          case 'footerSnippet':  {    message = required.footerSnippet  }break;
          case 'tax':  {    message = required.tax  }break;
          case 'siteName':  {    message = required.siteName  }break;
          case 'siteMentor':  {    message = required.siteMentor  }break;
          case 'maxRadius':  {    message = required.maxRadius  }break;
          case 'type':  {    message = required.type  }break;
          case 'maxNumber':  {    message = required.maxNumber  }break;
          case 'position':  {  message = required.position}break;
          case 'question':{  message = required.question}break;
          case 'answer':{  message = required.answer}break;
          case 'orderIds':{  message = required.orderIds}break;
          case 'driverMaxRadius':{  message = required.driverMaxRadius}break;
          case 'deliveryCostPerKm':{  message = required.deliveryCostPerKm}break;
          case 'vehicle':{  message = required.vehicle}break;
          case 'vehicleNo':{message = required.vehicleNo}break;
          case 'SKU':{  message = required.SKU}break;
          case 'fbUrl': { message = required.fbUrl; } break;
          case 'instagramUrl': { message = required.instagramUrl; } break;
          case 'twitterUrl': { message = required.twitterUrl; } break;
          case 'linkedInUrl': { message = required.linkedInUrl; } break;
          case 'pinterestUrl': { message = required.pinterestUrl; } break;
          case 'taxPercent': { message = required.taxPercent; } break;
          case 'emailIdForgot': { message = required.emailIdForgot; } break;
          case 'timeZone': { message = required.timeZone; } break;
          case 'measurement': { message = required.measurement; } break;
          case 'chargeCommission ': { message = required.chargeCommission ; } break;
          case 'countryId ': { message = required.countryId ; } break;
          case 'cityId ': { message = required.cityId ; } break;
          case 'categoryId ': { message = required.categoryId ; } break;
          case 'productId ': { message = required.productId ; } break;
          case 'brandPromo ': { message = required.brandPromo ; } break;
          case 'salePrice ': { message = required.salePrice ; } break;
          case 'smtpPassword ': { message = required.smtpPassword ; } break;
          case 'smtpUserName ': { message = required.smtpUserName ; } break;
          case 'Aadhaar ': { message = required.Aadhaar ; } break;
          case 'razorpayLinkedAccountId': { message = required.razorpayLinkedAccountId; } break;
          case 'referrerPoints': { message = required.referrerPoints; } break;
          case 'refereePoints': { message = required.refereePoints; } break;
          case 'sitemetaTitle': { message = required.sitemetaTitle; } break;
        }
      } else if (error.pattern) {
        const pattern = this.MSG.ERROR.PATTERN;
        switch (field) {
          case 'emailId': { message = pattern.Email; } break;
          case 'newPassword': { message = pattern.newPassword; } break;
          case 'confirmPassword': { message = pattern.confirmPassword; } break;
          case 'adminEmail': { message = pattern.adminEmail; } break;
          case 'fromEmail': { message = pattern.fromEmail; } break;
          case 'defaultFromEmail': { message = pattern.Email; } break;
          case 'defaultAdminEmail': { message = pattern.Email; } break;
          case 'pageUrl': { message = pattern.pageUrl; } break;
          case 'bannerUrl': { message = pattern.bannerUrl; } break;
          case 'fbUrl': { message = pattern.fbUrl; } break;
          case 'instagramUrl': { message = pattern.instagramUrl; } break;
          case 'twitterUrl': { message = pattern.twitterUrl; } break;
          case 'linkedInUrl': { message = pattern.linkedInUrl; } break;
          case 'pinterestUrl': { message = pattern.pinterestUrl; } break;
          case 'emailIdForgot': { message = pattern.emailIdForgot; } break;
        }
      } else if (error.minlength) {
        const minlength = this.MSG.ERROR.MINLENGTH;
        switch (field) {
          case 'mobile': { message = minlength.PhoneNo; } break;
          case 'mobileNo': { message = minlength.mobileNo; } break;
          case 'confirmPassword': { message = minlength.confirmPassword; } break;
          case 'newPassword': { message = minlength.newPassword; } break;
          case 'port': { message = minlength.port; } break;

        }
      } else if (error.maxlength) {
        const maxlength = this.MSG.ERROR.MAXLENGTH;
        switch (field) {
          case 'mobileNo': { message = maxlength.mobileNo; } break;
          case 'mobile': { message = maxlength.mobile; } break;
        }
      }
      return message;
    }
  }
  /*****************************************************************************************/
}
