import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// modal
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

// constants
import { URLConstants } from '../../../common/constants/routerLink-constants';

// services
import { BroadcasterService } from 'src/app/common/service/services/broadcaster.service';
import { LocalStorageService } from 'src/app/common/service/services/local-storage.service';
import { CommonService } from '../../../common/service/services/common.service';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { ShowErrorService } from '../../../common/service/services/show-error.service';
import { RestrictKeyPressService } from '../../../common/service/services/restrict-key-press.service';

// json
import  *  as  currencies  from  'src/app/shared/json/currency.json';
import  *  as  timezones  from  'src/app/shared/json/timezone.json';
import * as countries from 'src/app/shared/json/country.json';

@Component({
  selector: 'app-general-master-modal',
  templateUrl: './general-master-modal.component.html',
  styles: [
  ]
})
export class GeneralMasterModalComponent implements OnInit {
  public callAPIConstants = callAPIConstants;
  title;
  commonObject:any = {};
  public submitted = false;
  public data;
  public type;
  public apiname;
  public countryList;
  public orderList;
  public timezones: any = timezones.default;
  public currencies: any = currencies.default;
  public countries: any = countries.default;
  filteredTimezones: any =[];
  measurementsList:any=['KG','ML','GM'];
  globalSettings: any ={};
  statusParams:any;
  constructor(public localStorageService:LocalStorageService,public modalService:BsModalService,public router: Router,public modalRef : BsModalRef, public commonService : CommonService,public showErrorService: ShowErrorService, public broadCasterService: BroadcasterService, public restrictKeyPressService: RestrictKeyPressService) {  
  }

  ngOnInit(): void {
    this.getCountriesDetails();
    if(this.type==='agentPayOutsList' || this.type==='payOutsList'){
      this.filterOrders('');
    } else if(this.type ==='driversPayOutsList'){
      this.filterOrders('');
    } else if(this.type==='agentListing' || this.type==='vendorList' || this.type==='driverListing'){
    } else{
      // this.filterOrders('');
    }
    if(this.data){    
      this.commonObject = this.data;
      if(this.type == 'CountriesListing'){
        this.commonObject.countryId = this.data._id;  
      }if(this.type == 'CurrencyListing'){
        if(this.data.countryId){
          this.commonObject.countryId = this.data.countryId._id;
        }
      }if(this.type == 'TimezoneListing'){
        if(this.data.countryId){
          this.commonObject.countryId = this.data.countryId._id;
          if(this.type == "TimezoneListing" ) {
            this.filteredTimezones = this.timezones.filter(a => a.name === this.data.countryName);
            this.commonObject.filteredTimezones = this.filteredTimezones[0].timezones;
      
          }
        }

      }
    }    
  }
  
  /****************************************************************************
  @Purpose     : Get Countries List
  @Parameter   : Na
  @Return      : Country List
  /****************************************************************************/
  //getcountries List API 
  getCountriesDetails(){
  let obj = {searchText: ''}
  this.commonService.callApi(this.callAPIConstants.GetCountriesList, obj, 'post', false, false, false).then((success) => {
    if (success.status === 1) {    
      this.countryList = success.data;
    }
  }); 
  }
  /****************************************************************************/


  /****************************************************************************
  @Purpose     : Filter Orders List
  @Parameter   : event
  @Return      : orderList
  /****************************************************************************/

  filterOrders(event){
    let obj = event ?  event.target.value : '';
    const filter_data = { filter: { orderId: obj}, orderIds: this.data.orderIds };  
    this.commonService.callApi(this.callAPIConstants.ordersFieldsListInPayouts, filter_data, 'post', false, false, false).then((success) => {
      if (success.status === 1) {
        this.orderList = success.data;  
        this.commonObject.orderIds = '';  
      }
    }); 
  }

/****************************************************************************/

  /****************************************************************************
  @Purpose     : Filter Orders Forgents
  @Parameter   : event
  @Return      : orderList
  /****************************************************************************/
  filterOrdersForgents(event){
    let obj = event ?  event.target.value : '';
    const filter_data = { filter: { fullName: ''}};  
    this.commonService.callApi(this.callAPIConstants.agentPayOutsFieldsList, filter_data, 'post', false, false, false).then((success) => {
      if (success.status === 1) {
        this.orderList = success.data;  
        this.commonObject.orderIds = '';  
      }
    }); 
  }
 /****************************************************************************/

  /****************************************************************************
  @Purpose     : Filter Orders For Drivers
  @Parameter   : event
  @Return      : orderList
  /****************************************************************************/

  filterOrdersForDrivers(event){
    let obj = event ?  event.target.value : '';
    const filter_data = { filter: { orderId: this.data.orderIds}, orderIds: this.data.orderIds };  
    this.commonService.callApi(this.callAPIConstants.ordersFieldsListInPayoutsForDriver, filter_data, 'post', false, false, false).then((success) => {
      if (success.status === 1) {
        this.orderList = success.data;  
        this.commonObject.orderIds = '';  
      }
    }); 
  }
   /****************************************************************************/


  /****************************************************************************
  @Purpose     : Calculate Payout Amount
  @Parameter   : event
  @Return      : commonObject.amount
  /****************************************************************************/
  calculatePayoutAmount(event){
     let obj:any={}
     obj = {'orderIds': this.commonObject.orderIds};
     if(this.type ==='payOutsList'){
       obj.vendorId=this.data._id;
     } else if(this.type ==='agentPayOutsList'){ obj.agentId=this.data._id;  }
     else{obj.driverId=this.data._id; }
    this.commonService.callApi(this.type==='payOutsList'?this.callAPIConstants.orderPayouts:this.type ==='agentPayOutsList'?this.callAPIConstants.orderPayoutsForAgent:this.callAPIConstants.orderPayoutsForDriver, obj, 'post', false, false, false).then((success) => {
      if (success.status === 1) {    
        this.commonObject.amount = success.data;
      }
    }); 
  }
  /****************************************************************************/
  

  /***************************************************************
    @Purpose : Add/Update Country/Timezone/Currency
  /***************************************************************/
  addCountryInfo(form, commonObject){
    this.submitted = true; 
    let obj;
    let obj1 :any={};
    if (form.valid) {

      if(this.type == 'CountriesListing'){
        this.apiname = callAPIConstants.AddUpdateCountry
      }
      else if(this.type == 'CurrencyListing'){
        this.apiname = callAPIConstants.AddUpdateCurrency;
        this.commonObject.currencyId = this.data._id;
      }
      else if(this.type == 'TimezoneListing'){
        this.apiname = callAPIConstants.AddUpdateTimezone;
        this.commonObject.timezoneId = this.data._id;
      }
      else if(this.type == 'measurementsList'){
        this.apiname = callAPIConstants.addUpdateMeasurement;
        this.commonObject.measurementId = this.data._id;
      }
      else if(this.type == 'payOutsList'){
        this.apiname = callAPIConstants.createPayoutForVendor;
        obj = { vendorId: this.data._id, amount: this.commonObject.amount, orderIds: this.commonObject.orderIds };
      }
      else if(this.type == 'agentPayOutsList'){
        this.apiname = callAPIConstants.createPayoutForAgent;
        obj = { agentId: this.data._id, amount: this.commonObject.amount, orderIds: this.commonObject.orderIds };
      }
      else if(this.type == 'driversPayOutsList'){
        this.apiname = callAPIConstants.createPayoutForDriver;
        obj = { driverId: this.data._id, amount: this.commonObject.amount, orderIds: this.commonObject.orderIds };
      }
      else if(this.type == 'faqList'){
        this.apiname = callAPIConstants.addUpdateFAQ;
        this.commonObject.faqId = this.data._id;
      } else if(this.apiname=this.type=='vendorList'?this.callAPIConstants.changeAdminApprovalStatus : this.type==='agentListing'?this.callAPIConstants.changeAgentApprovalStatus:this.callAPIConstants.changeDriverApprovalStatus){
        obj1['approvalStatus']='rejected';
        obj1['driverIds']=[this.data._id];
        obj1['rejectionReason']=this.commonObject.reason;
      }
      const apiData = (this.type === 'vendorList' ||this.type=='agentListing' ||this.type=='driverListing'?obj1:this.type === 'payOutsList' ||this.type==='agentPayOutsList' || this.type ==='driversPayOutsList' ? obj : this.commonObject);  
      this.commonService.callApi(this.apiname, apiData, 'post', false, false).then((res) => {
        if(res.status == 1){
          this.showErrorService.popToast('success', res.message);
          this.modalRef.hide();
          // window.location.reload();
          this.modalService.onHidden.emit({ data: true })
        }
        else{
          this.showErrorService.popToast('error', res.message);
        }
      })
    }
    else{
      this.submitted = true;
    }
  }
   // *************************************************************//
   setAddress(event){
    this.commonObject.address = event.formatted_address;
    this.commonObject.area = event.address;
    this.commonObject.city = event.city;
    this.commonObject.state = event.state;
    this.commonObject.postalCode = event.postalCode;
    this.commonObject.country = event.country;
    this.commonObject.countryCode = event.countryCode;
    this.commonObject.latitude = event.lat;
    this.commonObject.longitude = event.lng;
    
  }
  Cancel(){
    this.modalRef.hide();
    this.modalService.onHidden.emit({ data: true })


  }
  restrictPaste(event){
    if(event){ return false;}
  }
  countryChanged(data){
    this.commonObject.countryCode=data.code;
    this.commonObject.phoneCode=data.phoneCode;
    this.commonObject.currency=data.currency;
    
    
  }
  
  onOptionsSelected(event) {
    if(this.type == "TimezoneListing" ) {
      delete this.commonObject.timeZone;
      this.filteredTimezones = this.timezones.filter(a => a.name === event.countryName);
      this.commonObject.filteredTimezones = this.filteredTimezones[0].timezones;

} 
    }
    ngDoCheck(): void {
      this.globalSettings = JSON.parse(this.localStorageService.getToken('globalSetting'));
    }
  
}
