<nav class="nav" role="navigation">
  <div class="header-brand">
    <div class="header-brand-inner">
      <a class="brand-logo" [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;">
        <img [src]="globalSettings ? uploadImgUrl+globalSettings?.siteLogoLarge : 'assets/images/riceday-logo.svg'" class="header-logo" alt="{{globalSettings ? globalSettings?.siteName : 'Fresh Mart' }}" title="{{globalSettings ? globalSettings?.siteName : 'Fresh Mart' }}" />
        <img [src]="globalSettings ? uploadImgUrl+globalSettings.siteLogoSmall : 'assets/images/resposive-logo' " class="header-icon" alt="{{globalSettings ?  globalSettings?.siteName :'Fresh Mart' }}" title="{{globalSettings ?  globalSettings?.siteName : 'Fresh Mart' }}" /></a>
      <div class="brand-tools">
      </div>
    </div>
  </div>
  <div class="text-center user-profile">
    <div class="thumb-img">
      <img [src]="photo  ? photo : 'assets/images/NoProfile.png'" alt="no img" />
    </div>
    <span>
      {{firstname}}
    </span>
  </div>
  <ul class="sidebar nav__list" (click)="checkSideNav($event)">
    <!-- DASHBOARD -->
    <li routerLinkActive='active' class="nav-item parent">
      <a class="nav-link" [routerLink]="[URLConstants.DASHBOARD]" href="javascript:;">
        <span class="nav-link-icon fa fa-bar-chart-o"></span>
        <span class="nav-link-text">Dashboard</span>
      </a>
    </li>
    <!-- USER -->
    <li class="nav-item parent"
      [hidden]="!accessPermission.userAccess.view && !accessPermission.adminUserAccess.view && !accessPermission.vendorAccess.view && !accessPermission.driverAccess.view">
      <a class="nav-link" href="javascript:;">
        <span class="nav-link-icon fa fa-users" aria-hidden="true"></span>
        <span class="nav-link-text">Manage Users</span>
        <span class="fa fa-angle-right drop-arrow arrow"></span>
      </a>
      <ul>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.adminUserAccess.view">
          <a [routerLink]="[URLConstants.ADMINUSER_LIST]">
            <span [ngClass]="onAdminUsersModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Admin Users</span></a>
        </li>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.userAccess.view">
          <a href="javascript:;" [routerLink]="[URLConstants.USERS]">
            <span [ngClass]="onUsersModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Customers</span>
          </a>
        </li>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.vendorAccess.view">
          <a [routerLink]="[URLConstants.VENDOR]">
            <span [ngClass]="onVendorModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Vendors</span></a>
        </li>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.driverAccess.view">
          <a [routerLink]="[URLConstants.DRIVER]">
            <span [ngClass]="onDriversModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Drivers</span></a>
        </li>
        <!-- *ngIf="accessPermission.adminUserAccess.view" -->
        <li routerLinkActive="sub-nav-active" >
          <a [routerLink]="[URLConstants.AGENTUSER_LIST]">
            <span [ngClass]="onAgentUsersModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em>Agents</span></a>
        </li>
      </ul>
    </li>
    <!-- PRODUCT CATALOG -->
    <li class="nav-item parent"
      [hidden]="!accessPermission.categoryAccess.view && !accessPermission.productAccess.view && !accessPermission.brandAccess.view && !accessPermission.productAccess.addProductInBulk">
      <a class="nav-link" href="javascript:;">
        <span class="nav-link-icon fa fa-sitemap" aria-hidden="true"></span>
        <span class="nav-link-text">Manage Catalog</span>
        <span class="fa fa-angle-right drop-arrow arrow"></span>
      </a>
      <ul>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.categoryAccess.view">
          <a href="javascript:;" [routerLink]="[URLConstants.CATEGORY]">
            <span [ngClass]="onCategoryModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Categories</span>
          </a>
        </li>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.productAccess.view">
          <a [routerLink]="[URLConstants.PRODUCTS]">
            <span [ngClass]="onProductModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Products</span></a>
        </li>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.brandAccess.view">
          <a [routerLink]="[URLConstants.BRAND]">
            <span [ngClass]="onBrandModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Brands</span></a>
        </li>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.productAccess.addProductInBulk">
          <a [routerLink]="[URLConstants.BULKUPLOADS]">
            <span [ngClass]="onBulkUploadsModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Bulk Uploads</span></a>
        </li>
      </ul>
    </li>
    <!-- SALES -->
    <li class="nav-item parent" [hidden]="!accessPermission.ordersAccess.view">
      <a class="nav-link" href="javascript:;">
        <span class="nav-link-icon fa fa-shopping-cart" aria-hidden="true"></span>
        <span class="nav-link-text">Sales</span>
        <span class="fa fa-angle-right drop-arrow arrow"></span>
      </a>
      <ul>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.ordersAccess.view">
          <a href="javascript:;" [routerLink]="[URLConstants.ORDER]">
            <span [ngClass]="onOrderModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Orders</span>
          </a>
        </li>
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.ordersAccess.view">
          <a href="javascript:;" [routerLink]="[URLConstants.RETURN]">
            <span [ngClass]="onReturnModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Returns</span>
          </a>
        </li>
        <!-- <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.ordersAccess.view">
          <a href="javascript:;" [routerLink]="[URLConstants.SHIPMENT]">
            <span [ngClass]="onShipmentModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Shipments</span>
          </a>
        </li> -->
        <li routerLinkActive="sub-nav-active" *ngIf="accessPermission.ordersAccess.view">
          <a href="javascript:;" [routerLink]="[URLConstants.TRANSACTION]">
            <span [ngClass]="onTransactionModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Transaction History</span>
          </a>
        </li>
      </ul>
    </li>
    <!-- Emails Management -->
    <li class="nav-item parent">
      <a class="nav-link" href="javascript:;">
        <span class="nav-link-icon fa fa-envelope-o" aria-hidden="true"></span>
        <span class="nav-link-text">Manage Email</span>
        <span class="fa fa-angle-right drop-arrow arrow"></span>
      </a>
      <ul>
        <li routerLinkActive="sub-nav-active">
          <a [routerLink]="[URLConstants.EMAIL_TEMPLATE_list]" href="javascript:;">
            <span [ngClass]="onEmailTemplateModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Email Templates</span></a>
        </li>
        <li routerLinkActive="sub-nav-active">
          <a [routerLink]="[URLConstants.SMTP_SETTING]" href="javascript:;">
            <span [ngClass]="onSMTPModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> SMTP Settings</span></a>
        </li>
      </ul>
    </li>
    <!-- Manage Roles -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.MANAGE_ROLES]">
        <span class="nav-link-icon fa fa-sliders"></span>
        <span class="nav-link-text">
          <span [ngClass]="onManageRolesModule ? 'sub-nav-active' : ''">Manage Roles</span></span>
      </a>
    </li>
    <!-- Review & Ratings -->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.RATING]">
        <span class="nav-link-icon fa fa-star"></span>
        <span class="nav-link-text">
          <span [ngClass]="onReviewModule ? 'sub-nav-active' : ''">Review & Ratings</span></span>
      </a>
    </li>
    <!-- Manage  Promocodes-->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.PROMOCODE]">
        <span class="nav-link-icon fa fa-tag"></span>
        <span class="nav-link-text">
          <span [ngClass]="onPromocodeModule ? 'sub-nav-active' : ''">Manage Promocodes</span></span>
      </a>
    </li>
    <!-- Manage  Payouts-->
      <!-- Emails Management -->
      <li class="nav-item parent">
        <a class="nav-link" href="javascript:;">
          <span class="nav-link-icon fa fa-dollar" aria-hidden="true"></span>
          <span class="nav-link-text">Manage Payouts</span>
          <span class="fa fa-angle-right drop-arrow arrow"></span>
        </a>
        <ul>
          <li routerLinkActive="sub-nav-active" >
            <a href="javascript:;" [routerLink]="[URLConstants.PAYOUTS]">
              <span [ngClass]="onPayoutModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                  aria-hidden="true"></em> Vendors</span>
            </a>
          </li>
          <li routerLinkActive="sub-nav-active" >
            <a href="javascript:;" [routerLink]="[URLConstants.PAYOUTSAGENT]">
              <span [ngClass]="onPayoutAgentsModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                  aria-hidden="true"></em> Agents</span>
            </a>
          </li>
          <li routerLinkActive="sub-nav-active" >
            <a href="javascript:;" [routerLink]="[URLConstants.PAYOUTSDRIVER]">
              <span [ngClass]="onPayoutDriversModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                  aria-hidden="true"></em> Drivers</span>
            </a>
          </li>
        </ul>
      </li>
    <!-- <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.PAYOUTS]">
        <span class="nav-link-icon fa fa-dollar"></span>
        <span class="nav-link-text">
          <span [ngClass]="onPayoutModule ? 'sub-nav-active' : ''">Payouts</span></span>
      </a>
    </li> -->
    <!-- Master Management -->
    <li class="nav-item parent" [hidden]="!(localStorageService.getToken('role') === adminRoleConstants.superAdmin)">
      <a class="nav-link" href="javascript:;">
        <span class="nav-link-icon fa fa-globe" aria-hidden="true"></span>
        <span class="nav-link-text">Master Management</span>
        <span class="fa fa-angle-right drop-arrow arrow"></span>
      </a>
      <ul>
        <li routerLinkActive="sub-nav-active">
          <a [routerLink]="[URLConstants.GENERAL]" href="javascript:;"><span [ngClass]="onGeneralModule ? 'sub-nav-active' : ''">
          <em class="fa fa-long-arrow-right"
              aria-hidden="true"></em> General</span></a>
        </li>
        <li routerLinkActive="sub-nav-active">
          <a [routerLink]="[URLConstants.MEASUREMENT]" href="javascript:;"><span [ngClass]="onMeasurementModule ? 'sub-nav-active' : ''">
          <em class="fa fa-long-arrow-right"
              aria-hidden="true"></em> Measurement</span></a>
        </li>
      </ul>
    </li>
    <!-- CONTENTS MANAGEMENT -->
    <li class="nav-item parent" [hidden]="!(localStorageService.getToken('role') === adminRoleConstants.superAdmin)">
      <a class="nav-link" href="javascript:;">
        <span class="nav-link-icon fa fa-pencil-square-o" aria-hidden="true"></span>
        <span class="nav-link-text">Manage Contents</span>
        <span class="fa fa-angle-right drop-arrow arrow"></span>
      </a>
      <ul>
        <!-- <li routerLinkActive="sub-nav-active">
          <a [routerLink]="[URLConstants.HOME]" href="javascript:;">
            <span [ngClass]="onHomeModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Home</span></a>
        </li> -->
        <li routerLinkActive="sub-nav-active">
          <a [routerLink]="[URLConstants.CMS]" href="javascript:;">
            <span [ngClass]="onCmsModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em>Static Pages</span></a>
        </li>
      </ul>
    </li>
    <!-- Manage  FAQs-->
    <li routerLinkActive='active' class="nav-item">
      <a class="nav-link" href="javascript:;" [routerLink]="[URLConstants.FAQ]">
        <span class="nav-link-icon fa fa-question"></span>
        <span class="nav-link-text">
          <span [ngClass]="onFAQModule ? 'sub-nav-active' : ''">FAQs</span></span>
      </a>
    </li>
    <!-- Settings -->
    <li class="nav-item parent" [hidden]="!(localStorageService.getToken('role') === adminRoleConstants.superAdmin)">
      <a class="nav-link" href="javascript:;">
        <span class="nav-link-icon fa fa-cog" aria-hidden="true"></span>
        <span class="nav-link-text">Settings</span>
        <span class="fa fa-angle-right drop-arrow arrow"></span>
      </a>
      <ul>
        <li routerLinkActive="sub-nav-active">
          <a href="javascript:;" [routerLink]="[URLConstants.GLOBAL_SETTINGS]"> <span
              [ngClass]="onGlobalSettingsModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Global settings</span> </a>
        </li>
        <!-- <li routerLinkActive="sub-nav-active">
          <a href="javascript:;" [routerLink]="[URLConstants.GLOBAL_SETTINGS]"> <span
              [ngClass]="onPaymentSettingsModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em>Payment Gateway SDK Details</span> </a>
        </li> -->
        <!-- <li routerLinkActive="sub-nav-active">
          <a href="javascript:;" [routerLink]="[URLConstants.NOTIFICATION]">
            <span [ngClass]="onNotificationModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Notification</span></a>
        </li> -->
        <li routerLinkActive="sub-nav-active">
          <a href="javascript:;" [routerLink]="[URLConstants.NOTIFICATION]">
            <span [ngClass]="onNotificationModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
                aria-hidden="true"></em> Notification</span></a>
        </li>
        <li routerLinkActive="sub-nav-active">
          <a [routerLink]="[URLConstants.SOCIAL_MEDIA]" href="javascript:;">
            <span [ngClass]="onSocialMediaModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
              aria-hidden="true"></em> Social Media</span></a>
        </li>
        <li routerLinkActive="sub-nav-active">
          <a [routerLink]="[URLConstants.TICKETLISTING]" href="javascript:;">
            <span [ngClass]="onTicketsModule ? 'sub-nav-active' : ''"><em class="fa fa-long-arrow-right"
              aria-hidden="true"></em>Ticket Listing</span></a>
        </li>
      </ul>
    </li>
  </ul>
</nav>