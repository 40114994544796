
<div class="modal-header" >
    <h4 class="modal-title"></h4>
    <a href="javascript:;" aria-label="Close" (click)="modalRef.hide()" class="modal-close pull-right">
        <em class="fa fa-close"></em>
    </a>
</div>
<div class="modal-body">
    <ng-container *ngIf="redirectFrom === 'promocode';else basicCropper">
        <image-cropper [imageURL]='imageChangedEvent' [maintainAspectRatio]="true" [aspectRatio]="2.2/1"  [imageQuality]="92" [resizeToWidth]="700" [resizeToHeight]="300" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </ng-container>
    <ng-template #basicCropper>
        <!-- <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true" [aspectRatio]="1/1"
        [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)"
        (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper> -->
        <image-cropper [imageURL]='imageChangedEvent' [maintainAspectRatio]="true" [aspectRatio]="1/1"  [imageQuality]="92" [resizeToWidth]="600" format="png" (imageCropped)="imageCropped($event)" (imageLoaded)="imageLoaded()" (loadImageFailed)="loadImageFailed()"></image-cropper>
    </ng-template>
</div>
<div class="modal-footer">
    <div class="row">
        <div class="col-md-12 text-right">
            <button *ngIf="imageChangedEvent" type="button" class="btn btn-primary" (click)="modalRef.hide()">Cancel</button>
            <button *ngIf="imageChangedEvent" type="button" class="btn btn-primary" (click)="submitcroppedImage()">Apply</button>
        </div>
    </div>
</div>



