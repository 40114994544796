import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

// plugins
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, merge, switchMap } from 'rxjs/operators';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { categoryConstants } from '../../../common/constants/categoryType-constants';

// Services
import { CommonService } from '../../../common/service/services/common.service';
import { LocalStorageService } from '../../../common/service/services/local-storage.service';
import { RestrictKeyPressService } from '../../../common/service/services/restrict-key-press.service';

@Component({
  selector: 'app-filter-type-ahead',
  templateUrl: './filter-type-ahead.component.html',
  styles: [],
})
export class FilterTypeAheadComponent implements OnInit {
  callAPIConstants = callAPIConstants;
  categoryConstants = categoryConstants;
  @Input() data: any;
  @Input() type: any;
  @Output() selectedValueOfFilter = new EventEmitter<any>();

  public getMethodName: any;
  public sendTypeForCategory: any;
  public parentCategory: string;
  public productFilterType: string;
  public filterlist: Observable<any>;
  public typeAheadSource = new Subject<string>();
  // focus$ = new Subject<string>();

  constructor(private commonService: CommonService, public restrictKeyPressService: RestrictKeyPressService,
    private localStorageService: LocalStorageService) {
  }

  ngOnInit() {
    if (this.type === 'userlist') {
      this.getMethodName = this.callAPIConstants.UserGetColumnData;
    } else if (this.type === 'adminUserlist' || this.type === 'vendorList') {
      this.getMethodName = this.callAPIConstants.AdminGetColumnData;
    } else if (this.type === 'driverListing') {
      this.getMethodName = this.callAPIConstants.DriverGetColumnData;
    } else if (this.type === 'cmslist') {
      this.getMethodName = this.callAPIConstants.CmsFilter;
    } else if (this.type === 'emailTemplateList') {
      this.getMethodName = this.callAPIConstants.EmialColumnData;
    } else if (this.type === 'roleList') {
      if (this.data.colName === 'Added By') {
        this.getMethodName = this.callAPIConstants.RoleGetaddedByColumnData;
      } else {
        this.getMethodName = this.callAPIConstants.RoleGetColumnData;
      }
    } else if (this.type === 'categorylist') {
      this.getMethodName = this.callAPIConstants.CategoryColumnData;
      this.sendTypeForCategory = this.categoryConstants.Category;
    } else if (this.type === 'subCategorylist') {
      this.getMethodName = this.callAPIConstants.CategoryColumnData;
      this.sendTypeForCategory = this.categoryConstants.SubCategory;
      this.parentCategory = this.localStorageService.getToken('parentCategoryId');
    } else if (this.type === 'subSubCategorylist') {
      this.getMethodName = this.callAPIConstants.CategoryColumnData;
      this.sendTypeForCategory = this.categoryConstants.SubSubCategory;
      this.parentCategory = this.localStorageService.getToken('parentSubCategoryId');
    } else if (this.type === 'productList') {
      if (this.data.colName === 'Category') {
        this.getMethodName = this.callAPIConstants.GetAllCategories;
        this.productFilterType = 'categoryName';
      } else {
        this.getMethodName = this.callAPIConstants.ProductFieldsList;
        this.data.isObj ? this.productFilterType = this.data.isObj : this.productFilterType = this.data.colFieldname;
      }
    } else if (this.type === 'brandlist') {
      this.getMethodName = this.callAPIConstants.brandFieldsList;
    } else if (this.type === 'orderList' || this.type === 'transactionList') {
      this.getMethodName = this.callAPIConstants.orderFieldsList;
    } else if (this.type === 'shipmentList') {
      this.getMethodName = this.callAPIConstants.shipmentsFieldsList;
    } else if (this.type === 'returnList') {
      this.getMethodName = this.callAPIConstants.returnsFieldsList;
    } else if (this.type === 'CountriesListing') {
      this.getMethodName = this.callAPIConstants.CountriesFieldsList;    
    }else if (this.type === 'TimezoneListing') {
      this.getMethodName = this.callAPIConstants.TimezonesFieldsList;    
    }else if (this.type === 'CurrencyListing') {
      this.getMethodName = this.callAPIConstants.CurrenciesFieldsList;    
    }else if (this.type === 'ProductReviewList' || this.type === 'VendorReviewList' || this.type=='DriverReviewList') {
      this.getMethodName = this.callAPIConstants.reviewFieldsList;  
    }else if (this.type === 'promoCodeList') {
      this.getMethodName = this.callAPIConstants.promoCodeFieldsList;
    }else if (this.type === 'vendorProductList') {
      this.getMethodName = this.callAPIConstants.vendorProductFieldsList;
    }else if (this.type === 'measurementsList') {
      this.getMethodName = this.callAPIConstants.measurementsFieldsList;
    }else if (this.type === 'payOutsList') {
      this.getMethodName = this.callAPIConstants.payOutsFieldsList;
    }else if (this.type === 'faqList') {
      this.getMethodName = this.callAPIConstants.FAQSFieldsList;
    } else if(this.type ==='agentListing') {
      this.getMethodName = this.callAPIConstants.agentFieldsList;
    }else if(this.type ==='agentPayOutsList') {
      this.getMethodName = this.callAPIConstants.agentPayOutsFieldsList;
    } else if(this.type ==='ticketList') {
      this.getMethodName = this.callAPIConstants.ticketsFieldsList;
    
    } else if(this.type ==='driversPayOutsList') {
      this.getMethodName = this.callAPIConstants.driverPayOutsFieldsList;
    }
    this.loadTypehead();
  }

  /*************************************************************
  @Purpose     : Load TypeAhead Initially
  @Parameter   : NA
  @Return      : NA
  /*************************************************************/
  private loadTypehead() {
    this.filterlist = concat(
      of([]),
      this.typeAheadSource.pipe(
        debounceTime(500),
        // merge(this.focus$),
        distinctUntilChanged(),
        switchMap((term) => this.getFilterSearchList(term)),
        map((response) => {
          return response;
        }),
      ),
    );
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : Get List of Filter on Search
  @Parameter   : value
  @Return      : items
  /*************************************************************/
  loader = false;
  getFilterSearchList(value: string = null): Observable<any> {
    this.loader = true;
    let dataParams: any;
    if (this.data.isArr) {
      dataParams = { filter: { [this.data.isArr]: [value] } };
    } else {
      this.data.isObj ? dataParams = { filter: { [this.data.isObj]: [value] } } :
        dataParams = { filter: { [this.data.colFieldname]: [value] } };
    }
    if (this.sendTypeForCategory) { dataParams['type'] = this.sendTypeForCategory; }
    if (this.parentCategory) { dataParams['parentCategory'] = this.parentCategory; }
    if (this.type === 'productList') { dataParams['type'] = this.productFilterType; dataParams['filter'] = { [this.productFilterType] : [value]} }
    if (this.type === 'vendorList') { dataParams['role'] = 'Vendor'; }
    if (this.type === 'VendorReviewList') { dataParams['reviewType'] = 'vendor';dataParams['type']=this.data.colFieldname }
    if (this.type === 'ProductReviewList') { dataParams['reviewType'] = 'product';dataParams['type']=this.data.colFieldname }
    if (this.type === 'DriverReviewList') { dataParams['reviewType'] = 'driver';dataParams['type']=this.data.colFieldname }
    if (this.type === 'ticketList') { dataParams['type'] = this.data.colFieldname; }
    if (this.type === 'promoCodeList') { dataParams['type'] = this.data.colFieldname; }

    let items = [];
    return this.commonService.callApiObservable(this.getMethodName, dataParams).pipe(
      catchError(() => of(({ items: [] }))),
      map((success) => {
        if (success['status'] == 1) {
          value ? items = success['data'] : items = [];
          this.loader = false;
        }
        return (items) ? items : [];
      }),
    );
  }
  /***************************************************************/

  /*************************************************************
  @Purpose     : Value emits on Selection
  @Parameter   : fieldName, $event
  @Return      : Breadcrumbs
  /*************************************************************/
  selectFilterValue(fieldName, event) {
    if(fieldName ==='role'){
      fieldName='role.role';
    }
    const lang = this.localStorageService.getToken('language');
    if (this.type === 'categorylist' || this.type === 'subCategorylist' || this.type === 'subSubCategorylist') {
      const eventArr = [];
      event.forEach((element) => { eventArr.push(element.categoryName); });
      event = eventArr;
    } else if (this.type === 'productList') {
      const eventArr = [];
      if (this.data.isArr || this.data.colFieldname === 'brandId' || this.data.colFieldname === 'vendorId') {
        event.forEach((element) => { eventArr.push(element._id); });
      } else if(this.data.colFieldname ==='parentCategory'){
        event.forEach((element) => { eventArr.push(element.categoryName); });
      } else if(this.data.colFieldname ==='brandName'){
        event.forEach((element) => { eventArr.push(element.brandName); });
      } else {
        event.forEach((element) => { eventArr.push(element.productName); });
      }
      event = eventArr;
    } else if (this.type === 'brandlist') {
      const eventArr = [];
      event.forEach((element) => { eventArr.push(element.brandName); });
      event = eventArr;
    } else if (this.type === 'roleList' && this.data.colName === 'Added By') {
      const eventArr = [];
      event.forEach((element) => { eventArr.push(element._id); });
      event = eventArr;
    } else if (this.type === 'orderList' && this.data.colName === 'Vendor') {
      const eventArr = [];
      event.forEach((element) => { eventArr.push(element.vendorName); });
      event = eventArr;
    } else if(this.type ==='promoCodeList' && this.data.colFieldname ==='vendorName'){
      const eventArr = [];
      event.forEach((element) => { eventArr.push(element.vendorName); });
      event = eventArr;


    }
    this.selectedValueOfFilter.emit({ fieldName, value: event });
  }
  /***************************************************************/
}
