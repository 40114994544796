import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, CanActivate,
  CanActivateChild, CanDeactivate, CanLoad, Router, RouterStateSnapshot,
} from '@angular/router';

// constants
import { URLConstants } from '../../constants/routerLink-constants';

// service
import { GetPermissionsService } from '../services/get-permissions.service';
import { LocalStorageService } from '../services/local-storage.service';

/****************************************************************************
@PURPOSE      : Dont allow public pages to get accessed. (After Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanLoginActivate implements CanActivate {
  URLConstants = URLConstants;
  constructor(public localStorageService: LocalStorageService, public router: Router) { }
  canActivate() {
    if (!this.localStorageService.getToken('accessToken')) {
      return true;
    }
    this.router.navigate([this.URLConstants.DASHBOARD]);
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow authorized pages to get accessed.  (Before Login)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanAuthActivate implements CanActivate {
  URLConstants = URLConstants;
  constructor(public localStorageService: LocalStorageService, public router: Router) { }
  canActivate() {
    if (this.localStorageService.getToken('accessToken')) {
      return true;
    }
    this.router.navigate([this.URLConstants.LOGIN]);
    return false;
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow to View any Module without permission (viewList)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanViewActivate implements CanActivate {
  public role: any;

  constructor(public getPermissionsService: GetPermissionsService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    this.role = this.getPermissionsService.getRolePermissions(route.data.role);
    if (route.data.type) {
      return this.getPermissionsService.CanActivateFalseRouteDashboard(this.role.addProductInBulk);
    } else {
      return this.getPermissionsService.CanActivateFalseRouteDashboard(this.role.view);
    }
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow to Create in any Module without permission (create)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanCreateActivate implements CanActivate {
  public role: any;

  constructor(public getPermissionsService: GetPermissionsService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    this.role = this.getPermissionsService.getRolePermissions(route.data.role);
    return this.getPermissionsService.CanActivateFalseRouteDashboard(this.role.create);
  }
}
/****************************************************************************/

/****************************************************************************
@PURPOSE      : Dont allow to Edit in any Module without permission (edit)
@PARAMETERS   : N/A
@RETURN       : <boolean>
/****************************************************************************/
@Injectable()
export class CanEditActivate implements CanActivate {
  public role: any;

  constructor(public getPermissionsService: GetPermissionsService) { }

  canActivate(route: ActivatedRouteSnapshot) {
    this.role = this.getPermissionsService.getRolePermissions(route.data.role);
    return this.getPermissionsService.CanActivateFalseRouteDashboard(this.role.edit);
  }
}
/****************************************************************************/
