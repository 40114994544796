<div class="page-wrap">
    <!-- START : Header -->
    <app-header></app-header>
    <!-- END : Header -->
    <div class="admin-wrapper">
        <!-- START : Sidebar -->
        <div class="admin-aside-left lightScroll" malihu-scrollbar [scrollbarOptions]="Verticalscrollbar">
            <app-sidebar></app-sidebar>
        </div>
        <!-- END : Sidebar -->
        <div class="admin-content-wrapper">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>
<!-- START : Footer -->
<!-- <footer class="page-footer">
    <div style="width: 100%;" class="text-center">Copyright &copy; {{currentYear}}. All rights reserved.</div>
</footer> -->
<!-- END : Footer -->
<div class="m-aside-left-overlay" (click)="overlayHide();"></div>