import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// modal
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

// service
import { CommonService } from '../../../common/service/services/common.service';
import { ShowErrorService } from '../../../common/service/services/show-error.service';
import { BroadcasterService } from 'src/app/common/service/services/broadcaster.service';
import { RestrictKeyPressService } from '../../../common/service/services/restrict-key-press.service';

// constants
import { URLConstants } from '../../../common/constants/routerLink-constants';
import { callAPIConstants } from '../../../common/constants/callAPI-constants';

import { __values } from 'tslib';
import { ValueConverter } from '@angular/compiler/src/render3/view/template';


@Component({
  selector: 'app-assign-modal',
  templateUrl: './assign-modal.component.html',
  styles: [
  ]
})
export class AssignModalComponent implements OnInit {
  public callAPIConstants = callAPIConstants;
  title;
  searchText;
  commonObject:any = {};
  public dataResp;
  public city;
  public submitted = false;
  public data;
  public type;
  public apiname;
  public driverList :any=[];
  public orderList;
public driver:any={};
public  allDrviersList: any =[];
  tabs: any ={};
  apiData: any ={};
  constructor(public modalService:BsModalService,
    public router: Router,
    public modalRef : BsModalRef, 
    public commonService : CommonService,
    public showErrorService: ShowErrorService, 
    public broadCasterService: BroadcasterService,
     public restrictKeyPressService: RestrictKeyPressService) {  
  }
  ngOnInit(): void {
    // this.filterOrders(event);
    // console.log(this.filterOrders);
    this.tabs.agents=true;
    this.apiData.type='agent';
    this.getDriverDetails();
    if(this.data){    
      this.commonObject = this.data;
      console.log(this.commonObject);      
      // this.checkDriver("");
     this.city = this.commonObject.shippingAddress.city;
     }     
  }
  onSelect(event) {
    if (event.heading === 'Agents') {
      this.tabs.agents = true;
      this.tabs.drivers = false;
    this.apiData.type='agent';
    this.searchText='';
    this.getDriverDetails();
    } else if (event.heading === 'Drivers') {
      this.tabs.agents = false;
      this.tabs.drivers = true;
    this.apiData.type='driver';
    this.searchText='';
    this.getDriverDetails();
    }

  }

  /*************************************************************
    @Purpose     : getDriverDetails List API 
    @Parameter   : NA
    @Return      : driverList
  /*************************************************************/
   
  getDriverDetails(){
    let obj = {assigneeType:this.apiData.type};
    this.commonService.callApi(this.callAPIConstants.getAssigneeList, obj, 'post', false, false, false).then((success) => {
      if (success.status === 1) {    
        this.driverList = success.data;
        console.log(this.driverList);
        
      }
    }); 
 }
   /*************************************************************/
  
   /*************************************************************
    @Purpose     : searchdriverInfo List API 
    @Parameter   : form, commonObject
    @Return      : NA
  /*************************************************************/
    
    searchdriverInfo(form,commonObject){
      this.submitted = true; 
      let obj;
      if (form.valid) {
        this.apiname = callAPIConstants.getAssigneeList;
       const apiData =this.commonObject.currencyId = this.data._id;
        this.commonService.callApi(this.apiname, apiData, 'post', false, false).then((res) => {
          if(res.status == 1){
            this.showErrorService.popToast('success', res.message);
            this.modalRef.hide();
            window.location.reload()
          }
          else{
            this.showErrorService.popToast('error', res.message);
          }
        })
      }  else{
        this.submitted = true;
      }
  
      
    }
 /*************************************************************/


/*************************************************************
  @Purpose    : Check driver list
  @Parameter  : form, client
  @Return     : driverList
  /*************************************************************/
  
  driversList: Array<any> = [];
  checkDriver(search) {
    const obj = {
      'searchText': search,
      assigneeType:this.apiData.type
    };
    this.commonService.callApi(this.callAPIConstants.getAssigneeList,obj,'post', false, false, false)
      .then((success) => { 
        if (success.status === 1) 
        { 
          this.driverList = success.data; 
        } 
      });
  }
    /*************************************************************/


/*************************************************************
  @Purpose    : assign driver list
  @Parameter  : driverId
  @Return     : NA
  /*************************************************************/
  assignDriver(driverId){
    let apiData:any={};
    apiData.orderId=this.data._id;
   if(this.tabs.agents){apiData.agentId=driverId}else{apiData.driverId=driverId};
    this.commonService.callApi(this.tabs.agents?this.callAPIConstants.assignAgent:this.callAPIConstants.assignDriver, apiData, 'post', false, false).then((res) => {
      if(res.status == 1){
        this.showErrorService.popToast('success', res.message);
        this.modalRef.hide();
        this.modalService.onHidden.emit({ data: true })
      }
      else{
        this.showErrorService.popToast('error', res.message);
      }
    })
  }
    /*************************************************************/

}
