<!-- Order basic info section starts -->
<div class="mt-4 Address-info">
    <div class="p-0 mb-3">
        <div class="animated fadeIn">
            <!-- <div class="card-header p-0 mb-3">
                <h5 class="card-title">Order &amp; Account Information</h5>
            </div> -->
            <div class="row">
                <!-- <h5 class="card-title">Order {{ orderDetail?.orderId }}</h5>
                <div class="table-responsive">
                    <table aria-describedby="mydesc" class="table dataTable with-image row-border hover custom-table">
                        <tbody>
                            <tr>
                                <td><strong>Order Date</strong></td>
                                <td class="text-right">{{ orderDetail?.createdAt | date }}</td>
                            </tr>
                            <tr>
                                <td><strong>Placed from IP</strong></td>
                                <td class="text-right">{{ orderDetail?.ipAddress }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div> -->
                <div class="col-md-6">
                    <div class="card-box">
                        <h5 class="card-title mb-4">Order Information</h5>
                        <div class="order-details-blog">
                            <ul>
                                <li>
                                    <p><strong><span class="order-date">Order ID: </span></strong><span>{{ orderDetail?.orderId }}</span></p>
                                </li>
                                <li>
                                    <p><strong><span class="order-date">Order Date: </span></strong><span>{{ orderDetail?.createdAt | date }}</span></p>
                                </li>
                                <li>
                                    <p><strong><span class="order-date">Placed From IP: </span></strong><span>{{ orderDetail?.ipAddress }}</span></p>
                                    
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card-box">
                        <h5 class="card-title mb-4">Account Information</h5>
                        <div class="order-details-blog">
                            <ul>
                                <li>
                                    <p><strong><span class="order-date">Customer Name: </span></strong><span>{{ orderDetail?.buyerName }}</span></p>
                                </li>
                                <li>
                                    <p><strong><span class="order-date">Email: </span></strong><span>{{ orderDetail?.emailId }}<a href="mailto:{{ orderDetail?.emailId }}"></a></span></p>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Order basic info section ends -->

<!-- Order status section starts -->
<div class="mt-5 Address-info" *ngIf="redirectFrom === 'infoTab'">
    <div class="p-0">
        <div class="animated fadeIn">
            <div class="card-box">
                <div class="p-0 mb-3">
                    <h5 class="card-title mb-4">Order Status</h5>
                </div>
                <div class="mx-auto">
                        <div class="row justify-content-between top progressbar-text">
                                <div class="row d-flex icon-content">
                                    <div class="d-flex flex-column">
                                        <p class="font-weight-bold"><em class="fa fa-file-text-o" aria-hidden="true"></em>Processing</p>
                                    </div>
                                </div>
                                <div class="row d-flex icon-content">
                                    <div class="d-flex flex-column">
                                        <p class="font-weight-bold"><em class="fa fa-cog" aria-hidden="true"></em>Dispatched</p>
                                    </div>
                                </div>
                                <div class="row d-flex icon-content">
                                    <div class="d-flex flex-column">
                                        <p class="font-weight-bold"><em class="fa fa-truck" aria-hidden="true"></em>Out For Delivery</p>
                                    </div>
                                </div>
                                <div class="row d-flex icon-content">
                                    <div class="d-flex flex-column">
                                        <p class="font-weight-bold"><em class="fa fa-home" aria-hidden="true"></em>Delivered</p>
                                    </div>
                                </div>
                            </div>
                    <div class="row d-flex justify-content-center">
                        <div class="col-12">
                            <ul id="progressbar" class="text-center">
                                <li [ngClass]="orderDetail.orderStatus =='delivered' ||orderDetail.orderStatus =='dispatched' ||orderDetail.orderStatus =='processing'|| orderDetail.orderStatus =='outForDelivery' ? 'active step0' : 'step0' "></li>
                                <li [ngClass]="orderDetail.orderStatus =='delivered' ||orderDetail.orderStatus =='dispatched' || orderDetail.orderStatus =='outForDelivery' ? 'active step0' : 'step0' "></li>
                                <li [ngClass]="orderDetail.orderStatus =='delivered'  || orderDetail.orderStatus =='outForDelivery'? 'active step0' : 'step0' "></li>
                                <li [ngClass]="orderDetail.orderStatus =='delivered' ? 'active step0' : 'step0' "></li>
                            </ul>
                        </div>
                    </div>
                    <!-- <div class="row justify-content-between top progressbar-number">
                        <div class="row d-flex icon-content">
                            <div class="d-flex flex-column">
                                <p class="font-weight-bold">{{ orderStatusDetails?.orderedQuantity }}</p>
                            </div>
                        </div>
                        <div class="row d-flex icon-content">
                            <div class="d-flex flex-column">
                                <p class="font-weight-bold">{{ orderStatusDetails?.shippedQuantity }}</p>
                            </div>
                        </div>
                        <div class="row d-flex icon-content">
                            <div class="d-flex flex-column">
                                <p class="font-weight-bold">{{ orderStatusDetails?.onTheWay }}</p>
                            </div>
                        </div>
                        <div class="row d-flex icon-content">
                            <div class="d-flex flex-column">
                                <p class="font-weight-bold">{{ orderStatusDetails?.deliveredQuantity }}</p>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Order status section ends -->

<!-- Order address section starts -->
<div class="mt-5 Address-info">
    <div class="animated fadeIn">
            <!-- <div class="card-header p-0 mb-3">
                <h5 class="card-title">Address Information</h5>
            </div> -->
            <div class="row info_order_address">
                <div class="col-md-6">
                    <div class="card-box" *ngIf="orderDetail?.billingAddress != 'undefined' && orderDetail?.billingAddress != ''">
                        <h5 class="card-title">Billing Address</h5>
                        <br><p><strong>{{ orderDetail?.billingAddress?.name }}</strong></p>
                        <p>{{ orderDetail?.billingAddress?.addressLine1+', '+orderDetail?.billingAddress?.addressLine2+', ' +orderDetail?.billingAddress?.city+', '+orderDetail?.billingAddress?.state+' - '+orderDetail?.billingAddress?.zipCode }}</p>
                        <p><em class="fa fa-phone" aria-hidden="true"></em> {{ orderDetail?.billingAddress?.mobileNo }}</p>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="card-box" *ngIf="orderDetail?.shippingAddress != 'undefined' && orderDetail?.shippingAddress != ''">
                        <h5 class="card-title">Shipping Address</h5>
                        <br><p><strong>{{ orderDetail?.shippingAddress?.name }}</strong>></p>
                        <p>{{ orderDetail?.shippingAddress?.addressLine1+', '+orderDetail?.shippingAddress?.addressLine2+', ' +orderDetail?.shippingAddress?.city+', '+orderDetail?.shippingAddress?.state+' - '+orderDetail?.shippingAddress?.zipCode }}</p>
                        <p><em class="fa fa-phone" aria-hidden="true"></em> {{ orderDetail?.shippingAddress?.mobileNo }}</p>
                    </div>
                </div>
            </div>

            <ng-container *ngIf="redirectFrom === 'returnTab'">
                <div class="card-header p-0 mb-3">
                    <h5 class="card-title">Driver Information</h5>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <agm-map [latitude]="lat" [longitude]="lng" style="height: 500px;">
                            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
                        </agm-map>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-12 mb-3 mt-3">
                        <ng-container *ngIf="orderDetail?.driver;else assignDriverCon">
                            Driver : {{ orderDetail?.driver }}
                            <button type="button" class="btn btn-primary" (click)="openModal()" style="vertical-align: inherit;" tabindex="0">Change Driver</button>
                        </ng-container>
                        <ng-template #assignDriverCon>
                            <button type="button" class="btn btn-primary" (click)="openModal()" style="vertical-align: inherit;" tabindex="0">Assign Driver</button>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
    </div>
</div>
<div class="mt-5 Address-info" *ngIf="orderDetail?.paymentType != 'COD'">
    <div class="p-0 mb-3">
        <div class="row">
            <div class="col-md-12">
                <div class="card-box">
                    <div class="card-header p-0">
                        <h5 class="card-title">Payment & Shipping Information</h5>
                    </div>
                    <table aria-describedby="mydesc" class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Transaction ID</th>
                                <th scope="col">Transaction Date</th>
                                <th scope="col">Amount</th>
                                <!-- <th scope="col">Transaction Status</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th scope="row">{{ orderDetail?.razorpayPaymentId }}</th>
                                <td>{{ orderDetail?.createdAt | date }}</td>
                                <td>{{ orderDetail?.totalAmount | currency : globalSettings.currency }}</td>
                                <!-- <td>{{ orderDetail?.status }}</td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div class="col-md-12">
                <div class="card-header p-0 mb-3">
                    <h5 class="card-title">Driver Information</h5>
                </div>
                <div class="row" *ngIf="redirectFrom === 'infoTab'">
                    <div class="col-md-12" *ngIf="driverDetails.length;else noDriver">
                        <p>Assigned Driver :</p>
                        <p class="d-flex align-items-center" *ngFor="let driver of driverDetails">
                            {{ driver?.driverId?.driverName }} <a *ngIf="driver?.shipmentStatus" class="driver-location-icon" href="javascript:;" [routerLink]="URLConstants.ORDER_SHIPMENT_VIEW+driver?._id" title="Track Driver"><em class="fa fa-map-marker" aria-hidden="true"></em></a>
                        </p>
                    </div>
                    <ng-template #noDriver>
                        <div class="col-md-12">
                            <p>Assigned Driver :  N/A</p>
                        </div>
                    </ng-template>
                    <div class="col-md-12">
                        <p class="tracking-driver">
                            <ng-container *ngIf="orderDetail.driverName != undefined;else newDriver">
                                <button type="button" class="btn btn-primary" (click)="openModal(changeDriver)" style="vertical-align: inherit;" tabindex="0">Change Driver</button>
                            </ng-container>
                            <ng-template #newDriver>
                                <button type="button" class="btn btn-primary" (click)="openModal(assignDriver)" style="vertical-align: inherit;" tabindex="0">Assign Driver</button>
                            </ng-template>
                        </p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>


<div class="mt-5 Address-info">
    <div class="row">
        <div class="col-md-12 item-order">
            <div class="card-box mb-3">
                <div class="p-0">
                    <h5 class="card-title mb-4">Items Ordered</h5>
                </div>
                <ng-container *ngIf="orderedProducts && orderedProducts.length">
                    <table aria-describedby="mydesc" class="table table-striped table-hover">
                        <thead>
                            <tr>
                                <th scope="col">Product Name</th>
                                <th scope="col">SKU</th>
                                <th scope="col">Unit Price</th>
                                <th scope="col">Order Quantity</th>
                                <th scope="col" *ngIf="orderData?.confirmOrder">Processing Quantity</th>
                                <th scope="col">Vendor Name</th>
                                <!-- <th scope="col" >Status</th> -->
                                <th scope="col">Sub Total</th>
                                <!-- <th scope="col">Action</th> -->
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of orderedProducts;let i=index">
                                <td scope="row">{{ item?.productName }}</td>
                                <td>{{ item?.SKU }}</td>
                                <td>{{ item?.salePrice | currency : globalSettings.currency }}</td>
                                <td *ngIf="redirectFrom === 'infoTab';else quantityData">
                                    Ordered : {{ item?.ordered }}<br />
                                    <ng-container *ngIf="item.delivered > 0">Shipped : {{ item?.delivered }}<br /></ng-container>
                                    <ng-container *ngIf="item.returned > 0">Return : {{ item?.returned }}<br /></ng-container>
                                    <ng-container *ngIf="item.cancelled > 0">Cancelled : {{ item?.cancelled }}<br></ng-container>
                                    <ng-container *ngIf="item.pending > 0">Pending : {{ item?.pending }}</ng-container>
                                </td>
                                <ng-template #quantityData>
                                    <td>{{ item?.quantity }}</td>
                                </ng-template>
                                
                                <td *ngIf="orderData?.confirmOrder">
                                    <div class="col-md-6">
                                        {{productsArray[i]['quantity'] ? productsArray[i]['quantity'] : item?.ordered}}
                                        <!-- <input type="text" id="{{i}}" disabled readonly class="form-control process_quantity" name="{{ item?.productId }}" (input)="updateProcessQuantity(i,$event.target.value,'update')" [value]= ""> -->
                                    </div>
                                </td>
                                <td>{{item?.vendorName}}</td>
                                <td>{{ item?.subTotal | currency : globalSettings.currency }}</td>
                                <!-- <td> <button class="btn btn-primary" *ngIf="!item.assigned">Assign</button>
                                    <button class="btn btn-primary" *ngIf="item.assigned">Assigned</button> </td> -->
                                <!-- <td *ngIf="orderedProducts.length > 1 && orderData?.confirmOrder">
                                    <div class="col-md-6" >
                                        <a href="javascript:;"><span><em (click)="updateProcessQuantity(i,'','delete')" class="fa fa-trash"></em></span></a>
                                    </div>
                                </td> -->
                            </tr>
                        </tbody>
                    </table>
                    <!-- <div class="col-md-6" *ngIf="orderData?.confirmOrder">
                        <a class="btn btn-success" href="javascript:;" (click)="confirmOrder()">Confirm Order</a>
                    </div> -->
                </ng-container>
                <button class="btn btn-primary" (click)="openModal()" [hidden]="orderDetail?.agentId || orderDetail?.driverId || orderDetail?.orderStatus=='cancelled'">Assign</button>
            </div>
        </div>
    </div>
    <div class="card-box mt-4">
        <div class="p-0 mb-3">
            <h5 class="card-title">Order Total</h5>
        </div>
        <div class="row">
            <div class="col-md-6">
                <form #form="ngForm" (ngSubmit)="saveComment(form,admin)" novalidate="" class="ng-untouched ng-pristine ng-valid pb-4">
                    <label class="d-block">
                        <h4 class="sub-title pull-left">Notes for this Order</h4>
                    </label>
                    <div class="form-group">
                        <textarea placeholder="Comment" class="form-control ng-untouched ng-pristine ng-valid" name="comment" rows="4" #comment="ngModel" [(ngModel)]="admin.comment" required></textarea>
                        <div class="error-block text-left" *ngIf="((submitted || comment.touched) && comment.invalid)">
                            {{showErrorService.showError('comment', comment.errors)}}
                        </div>
                    </div>
                    <label>
                        <input name="emailToCustomers" ng-checked="{{admin.emailToCustomers}" type="checkbox" ng-reflect-model="true" class="ng-untouched ng-pristine ng-valid" /><em class="input-helper"></em> <span class="ml-2">Notify Customer by Email</span>
                    </label>
                    <br />
                    <button class="btn btn-primary mt-4 ml-0" type="submit">Submit Comment</button>
                </form>
            </div>
    
            <div class="col-md-6">
                <label><h4 class="sub-title">Order Totals</h4></label>
                <table aria-describedby="mydesc" class="table dataTable with-image row-border hover custom-table pb-4">
                    <tbody>
                        <tr class="animated fadeIn">
                            <td>Subtotal</td>
                            <td class="text-right">{{ orderTotals?.subTotal | currency : globalSettings.currency }}</td>
                        </tr>
                        <tr class="animated fadeIn">
                            <td>Shipping Cost</td>
                            <td class="text-right">{{ orderTotals?.shippingCost | currency : globalSettings.currency }}</td>
                        </tr>
                        <tr class="animated fadeIn">
                            <td>Tax</td>
                            <td class="text-right">{{ orderTotals?.taxAmount | currency : globalSettings.currency }}</td>
                        </tr>
                        <tr class="animated fadeIn">
                            <td><strong>Grand Total</strong></td>
                            <td class="text-right"><strong>{{ orderTotals?.grandTotal | currency : globalSettings.currency }}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
    
<!-- ASSIGN DRIVER MODAL -->
<ng-template #assignDriver>
  <app-assign-driver action="assign"></app-assign-driver>
</ng-template>
<!-- ASSIGN DRIVER MODAL -->

<!-- CHANGE DRIVER MODAL -->
<ng-template #changeDriver>
  <app-assign-driver action="change" [shipmentId]="orderDetail?.shipmentId"></app-assign-driver>
</ng-template>
<!-- CHANGE DRIVER MODAL -->    