import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// service
import { BroadcasterService } from './services/broadcaster.service';
import { CommonService } from './services/common.service';
import { GetPermissionsService } from './services/get-permissions.service';
import { LocalStorageService } from './services/local-storage.service';
import { LogoutService } from './services/logout.service';
import { RestrictKeyPressService } from './services/restrict-key-press.service';
import { ShowErrorService } from './services/show-error.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
  ],
  providers: [
    BroadcasterService,
    CommonService,
    LocalStorageService,
    LogoutService,
    RestrictKeyPressService,
    ShowErrorService,
    GetPermissionsService,
  ],
})
export class ServiceModule { }
