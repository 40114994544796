export const environment = {
    production: true,
    environmentName: 'staging',
    port: 4088,
    apiUrl: 'https://riceapi.inic.cloud/api/',
    uploadImgUrl: 'https://riceapi.inic.cloud/public/upload/',
    csvUrl: 'https://riceapi.inic.cloud/public/csv/',
    excelUrl: 'https://riceapi.inic.cloud/public/excel/',
    pdfUrl: 'https://riceapi.inic.cloud/public/pdf/',
    dateFormat: 'dd MMM yyy',
    currencyCode: 'USD',
};
