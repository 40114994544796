import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// Services
import { CommonService } from '../../../common/service/services/common.service';
import { ShowErrorService } from '../../../common/service/services/show-error.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styles: [],
})
export class PasswordComponent implements OnInit {
  URLConstants = URLConstants;
  callAPIConstants = callAPIConstants;
  @Input() passwordData;
  public getMethodName: any;
  public token: any;

  constructor(public showErrorService: ShowErrorService, private commonService: CommonService, private router: Router) {
    this.token = this.router.parseUrl(this.router.url).queryParams['token'];
  }

  ngOnInit() {
    if (this.passwordData.type === 'setPassword') {
      this.getMethodName = this.callAPIConstants.AdminResetPassword; //AdminSetPassword
    } else if (this.passwordData.type === 'resetPassword') {
      this.getMethodName = this.callAPIConstants.AdminResetPassword;
    }
  }
  /*************************************************************
	@Purpose     : set or reset password
	@Parameter   : NA
	@Return      : url
	/*************************************************************/
  public password: any = {};
  public submitted = false;
  onSubmitPassword(passwordForm, password) {
    this.submitted = true;
    if (passwordForm.valid) {
      if (password.password !== password.confirmpassword) {
        this.showErrorService.popToast('error', 'New Password and confirm password must be same.');
        passwordForm.reset();
        return false;
      }
      const fromObject = {
        token: this.token,
        password: password.password,
      };
      this.commonService.callApi(this.getMethodName, fromObject, 'post', true, false).then((success) => {
        if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
          this.router.navigate([this.URLConstants.LOGIN]);
        } else {
          this.showErrorService.popToast('error', success.message);
        }
      });
    }
  }
  /*************************************************************/
}
