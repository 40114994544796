<div class="authentic-page">
  <div class="back-img">
  <div class="container">
    <div class="admin-back-img">
     <!-- <img src="assets/images/admin-background-banner.png" class="img-fluid admin-back-img">-->
  <div class="authentic-wrapper">
    <div class="row">
     
      <div class="col-md-6">
        <div class="login-container">
          <div class="login-section">
            <!--<h3 class="pb-2">Sign in to your account</h3>-->
            <!--<h4>Welcome Back</h4>-->
            <div class="text-center"><img src="assets/images/riceday-logo.svg" class="img-fluid" alt="no img"></div>
            <h5 class="background"><span>Login</span></h5>
            <form class="example-form" #loginForm="ngForm" (ngSubmit)="onLogin(loginForm, user)"
              content-type="application/x-www-form-urlencoded">
              <div class='form-group row'>
                <label htmlFor="Email">Email Address</label>
                <div class="col-md-12" [ngClass]="((submitted || emailId.touched) && emailId.invalid)?'has-danger':''">
                  <input class="form-control" placeholder="Email Address" type="emailId" name="emailId"
                    pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" autocomplete="on" [(ngModel)]="user.emailId"
                    value="" id="emailId" required #emailId="ngModel" />
                  <em class='fa fa-envelope' aria-hidden='true'></em>
                  <div class="error-block text-left" *ngIf="((submitted || emailId.touched) && emailId.invalid)">
                    {{showErrorService.showError('emailId', emailId.errors)}}
                  </div>
                </div>
              </div>
              <div class='form-group row'>
                <label htmlFor="Password">Password</label>
                <div class="col-md-12"
                  [ngClass]="((submitted || password.touched) && password.invalid)?'has-danger':''">
                  <input class="form-control" placeholder="Password" type="password" name="password"
                    [(ngModel)]="user.password"  value="" autocomplete="on" id="password" required
                    #password="ngModel" />
                  <em class='fa fa-lock' aria-hidden='true'></em>
                  <div class="error-block text-left" *ngIf="((submitted || password.touched) && password.invalid)">
                    {{showErrorService.showError('password', password.errors)}}
                  </div>
                </div>
              </div>
              <div class='row mt-60'>
                <div class='col-md-6 col-sm-12'>
                  <div class='checkbox sm-text-left'>
                    <label>
                      <input type="checkbox" name="status" [(ngModel)]="user.rememberMe">
                      <span></span> Remember Password
                    </label>
                  </div>
                </div>
                <div class='col-md-6 col-sm-12'>
                  <div class='text-right sm-text-left'>
                    <a class='auth-link text-gray' [routerLink]="URLConstants.FORGOT_PASS"> Forgot password? </a>
                  </div>
                </div>
              </div>
              <div class="mt-15">
                <button class='btn btn-block btn-primary ml-0' type='submit'> Login <span><em class="fa fa-arrow-right" aria-hidden="true"></em></span></button>
              </div>
              
            </form>
          </div>
        </div>
      </div>
      <div class="col-md-6 login-logo">
        <img src="assets/images/admin-login.png" alt="no img" class="header-logo img-fluid" alt="IndiaNic" title="IndiaNic" />
      </div>
    </div>
  </div>
</div>
</div>
</div>
</div>
<div class="container" style="display: none;">
  <div class="offset-md-3 col-md-6 text-center">
    <div class="card bg-light text-dark">

      <div class="card-header align-header-center"><strong>Payment Form</strong></div>

      <div class="card-body">
        <form [formGroup]="paymentForm" (ngSubmit)="onSubmit()">

          <div class="form-group">
            <label class="form-label">First Name :</label>
            <input type="text" formControlName="firstname" required>
          </div>

          <div class="form-group">
            <label class="form-label">Last Name:</label>
            <input type="text" formControlName="lastname" required>
          </div>

          <div class="form-group">
            <label class="form-label">Email :</label>
            <input type="email" formControlName="email" email required>
          </div>

          <div class="form-group">
            <label class="form-label">Phone :</label>
            <input type="number" formControlName="phone" [maxlength]="10" [minLength]="10" required>
          </div>

          <div class="form-group">
            <label class="form-label">Amount :</label>
            <input type="number" formControlName="amount" required>
          </div>

          <div class="form-group">
            <label class="form-label">Product Info :</label>
            <input type="text" formControlName="productinfo" required>
          </div>

          <div class="form-group align-header-center">
            <button type="submit" class="btn btn-primary" [disabled]="!paymentForm.valid">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
