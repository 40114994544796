import { AfterViewInit, Component, DoCheck, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';

// environment
import { environment } from 'src/environments/environment';

// plugins
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as countryCurrencyMap from 'country-currency-map';

// constants
import { callAPIConstants } from '../../../common/constants/callAPI-constants';
import { categoryConstants,MAX_RATING } from '../../../common/constants/categoryType-constants';
import { URLConstants } from '../../../common/constants/routerLink-constants';

// Services
import { CommonService } from '../../../common/service/services/common.service';
import { LocalStorageService } from '../../../common/service/services/local-storage.service';
import { RestrictKeyPressService } from '../../../common/service/services/restrict-key-press.service';
import { ShowErrorService } from '../../../common/service/services/show-error.service';

// interfaces
import { GeneralMasterModalComponent} from '../../modals/general-master-modal/general-master-modal.component'
import { BroadcasterService } from 'src/app/common/service/services/broadcaster.service';


@Component({
  selector: 'app-responsive-data-table',
  templateUrl: './responsive-data-table.component.html',
  styles: [],
})
export class ResponsiveDataTableComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
  modalRef : BsModalRef;

  public country: any = {};
  public Timezone: any = {};

  public listingAddUpdateBroadCaster;
  public currencyAddUpdateBroadCaster;

  showGridLayout: boolean;
  callAPIConstants = callAPIConstants;
  URLConstants = URLConstants;
  categoryConstants = categoryConstants;
  MAX_RATING = MAX_RATING;
  public uploadImageUrl = environment.uploadImgUrl;
  public globalSettings: any ={};
  public csvUrl = environment.csvUrl;
  public excelUrl = environment.excelUrl;
  public pdfUrl = environment.pdfUrl;
  public columnVisStatus = true;
  dtOptions: any = {};
  formDateRangepicker = [];
  languageList = [ {id:'en',name:'English'},{id:'ar',name:'Arabic'}];

  // dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  APIparameters: any = {};
  total: any;
  public type: any;
  public submitted = false;
  public selectedUserList: any[] = [];
  public columnVisibilityOptions = false;
  public filterColumnOption = false;
  public params: any;
  public conditions: any;
  public language: any;
public ratingList:any=[1,2,3,4,5];
  showSkeletonLoader: boolean;
  @Input() accessPermission: any;
  @Input() tableSetupData: any;
  public tempData: any = [];
  public tableConfig: any;
  tableData = {
    cols: ['id', 'author', 'title'],
    data: [],
    rowSelected: 0,
    value: false,
    type: '',
  };
public approvalStatusList:any=[{name:'Pending',value:'pending'},{name:'Approved',value:'approved'},{name:'Rejected',value:'rejected'}];
public ticketStatusList:any=[{name:'Open',value:'Open'},{name:'In Progress',value:'InProgress'},{name:'Resolved',value:'Resolved'},{name:'Closed',value:'Closed'}];
  @ViewChild('customPagination', { static: false }) customPagination: any;
  i: any;
  driverListArr: any;
  constructor(public commonService: CommonService, public showErrorService: ShowErrorService,
    public router: Router, public BsModalRef: BsModalRef, private modalService: BsModalService,
    public restrictKeyPressService: RestrictKeyPressService, private localStorageService: LocalStorageService, public broadCaster : BroadcasterService) {
      this.modalService.onHidden.subscribe((res) => {
        this.callApiToGetData();
  
      });
  }

  ngOnInit() {
    let body = document.getElementsByTagName('body')[0];
    // body.classList.remove("className");   //remove the class
    
    this.tableConfig = this.tableSetupData;
    this.type = this.tableConfig.type;
    this.APIparameters = { page: 1, pagesize: 10, columnKey: this.type };
    this.params = this.tableConfig.params;
    this.conditions = this.tableConfig.conditions;
    if(this.type ==='ticketList' || this.type === 'ProductReviewList' || this.type === 'VendorReviewList' || this.type==='DriverReviewList')
{
  body.classList.add("className");   //add the class
}
if(this.type ==='driverListing'){
  this.getDriverList();
}
    /*************************************************************
    @Purpose : Change statusList according to role Permission
    /*************************************************************/
    if (this.accessPermission && this.accessPermission.delete || (this.accessPermission && this.accessPermission.edit && this.type === 'roleList')) {
      if (this.type === 'categorylist' || this.type === 'subCategorylist' || this.type === 'subSubCategorylist' || this.type === 'productList') {
        this.statusList = ['Published', 'UnPublished', 'Delete'];
      } else if(this.type==='ticketList'){
        this.statusList = ['Open','InProgress', 'Resolved','Closedd'];
      } else {
        this.statusList = ['Active', 'Inactive', 'Delete'];
      }
      if (this.accessPermission.edit && this.type === 'roleList') { this.statusList = ['Active', 'Inactive']; }
    } else {
      this.conditions.showApplyStatus = false;
      const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
      if (this.tableConfig.cols[index]) { this.tableConfig.cols[index].isVisible = false; }
    }
    /*************************************************************/

  }
getDriverList(event?){
  let data:any={};
  if(event){data.searchText=event.target.value}
  this.commonService.callApi(this.callAPIConstants.getAgentsList,data,'post').then(res=>{
    if(res.status === 1){
      res.data.push({fullName:'Admin',_id:'Admin'});
      this.driverListArr=res.data;
      
    }
  })
}
  ngDoCheck(): void {
    this.globalSettings = JSON.parse(this.localStorageService.getToken('globalSetting'));

    this.language = this.localStorageService.getToken('language');
  }

  ngAfterViewInit() {
    this.callApiToGetData();
    // options for datatable
    this.dtOptions = {
      destroy: true,
      retrieve: true,
      searching: false,
      sorting: false,
      ordering: false,
      autoWidth: false,
      paging: false,
      info: false,
      responsive: {
        details: {
          renderer: Responsive.renderer.listHiddenNodes(),
        },
      },
      columnDefs: [
        { responsivePriority: 1, targets: 0 },
        { responsivePriority: 1, targets: -1 },
        { responsivePriority: 1, targets: -2 },
      ],
    };
  }

  ngOnDestroy() {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    let body = document.getElementsByTagName('body')[0];
    if(this.type ==='ticketList' || this.type === 'ProductReviewList' || this.type === 'VendorReviewList' || this.type==='DriverReviewList')
    {
      body.classList.remove("className");   //remove the class
    }
  }

  /*************************************************************
    @Purpose : Api for List
  /*************************************************************/
  public getMethodName: any;
  public CSV: any;
  public EXCEL: any;
  downloadFiles: any;
  public DeleteUser: any;
  public ChangeStatus: any;
  public colVisible: any;
  public saveFilter: any;
  public getFilter: any;
  public deleteFilter: any;

  // tslint:disable-next-line: cognitive-complexity
  async callApiToGetData() {
    if (this.type) {
      if (this.type === 'userlist') {
        this.getMethodName = this.callAPIConstants.UserList;
        this.downloadFiles = this.callAPIConstants.downloadUserFile;
        this.DeleteUser = this.callAPIConstants.UserDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.UserChangeStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
      } else if (this.type === 'adminUserlist') {
        this.getMethodName = this.callAPIConstants.AdminUserlist;
        this.downloadFiles = this.callAPIConstants.downloadAdminFile;
        this.DeleteUser = this.callAPIConstants.AdminDeleteUsers;
        this.ChangeStatus = this.callAPIConstants.AdminChangeStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;

      } else if (this.type === 'agentListing') {
        this.getMethodName = this.callAPIConstants.agentListing;
        this.downloadFiles = this.callAPIConstants.DownloadAgent;
        this.DeleteUser = this.callAPIConstants.DeleteAgents;
        this.ChangeStatus = this.callAPIConstants.AgentChangeStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;

      } else if (this.type === 'vendorList') {
        this.APIparameters['role'] = 'Vendor';
        this.getMethodName = this.callAPIConstants.AdminUserlist;
        this.ChangeStatus = this.callAPIConstants.AdminChangeStatus;
        this.downloadFiles = this.callAPIConstants.downloadAdminFile;
        this.DeleteUser = this.callAPIConstants.AdminDeleteUsers;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
      } else if (this.type === 'driverListing') {
        this.getMethodName = this.callAPIConstants.DriverList;
        this.ChangeStatus = this.callAPIConstants.DriverChangeStatus;
        this.downloadFiles = this.callAPIConstants.DriverDownloadFiles;
        this.DeleteUser = this.callAPIConstants.DriverDelete;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
      } else if (this.type === 'statelist') {
        this.getMethodName = this.callAPIConstants.stateLisitng;
        this.downloadFiles = this.callAPIConstants.downloadStateFile;
        this.DeleteUser = this.callAPIConstants.deleteStates;
        this.ChangeStatus = this.callAPIConstants.changeStateStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
      } else if (this.type === 'emailTemplateList') {
        this.getMethodName = this.callAPIConstants.EmailTemplateList;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.DeleteUser = this.callAPIConstants.DeleteEmailTemplate;
        this.ChangeStatus = this.callAPIConstants.EmailChangeStatus;
      } else if (this.type === 'cmslist') {
        this.getMethodName = this.callAPIConstants.CmsList;
        this.ChangeStatus = this.callAPIConstants.CMSChangeStatus;
        this.DeleteUser = this.callAPIConstants.CmsDelete;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.downloadFiles=this.callAPIConstants.CMSDownloadforDataTable;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
      } else if (this.type === 'roleList') {
        this.getMethodName = this.callAPIConstants.RoleList;
        this.ChangeStatus = this.callAPIConstants.RoleChangeStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
      } else if (this.type === 'userOrderList') {
        this.getMethodName = this.callAPIConstants.UserOrderList;
        this.APIparameters['userId'] = this.params.statusParams;
      } else if (this.type === 'wishListInUsersView') {
        this.getMethodName = this.callAPIConstants.UserWishListListing;
        this.APIparameters['userId'] = this.params.statusParams;
      } else if (this.type === 'categorylist' || this.type === 'subCategorylist' || this.type === 'subSubCategorylist') {
        this.APIparameters['type'] = this.tableConfig.pageType;
        if (this.tableConfig.parentCategory) { this.APIparameters['parentCategory'] = this.tableConfig.parentCategory; }
        this.getMethodName = this.callAPIConstants.CategoryList;
        this.ChangeStatus = this.callAPIConstants.CategoryChangeStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.DeleteUser = this.callAPIConstants.CategoryDelete;
        this.downloadFiles = this.callAPIConstants.CategoryDownloadforDataTable;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
      } else if (this.type === 'productList') {
        this.getMethodName = this.callAPIConstants.ProductList;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.DeleteUser = this.callAPIConstants.ProductDelete;
        this.downloadFiles = this.callAPIConstants.ProductDownloadforDataTable;
        this.ChangeStatus = this.callAPIConstants.ProductChangeStatus;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
      } else if (this.type === 'relatedItemsList') {
        this.getMethodName = this.callAPIConstants.RelatedProductList;
        this.APIparameters['productId'] = this.params.statusParams;
        this.DeleteUser = this.callAPIConstants.RelatedProductDelete;
        
      } else if (this.type === 'brandlist') {
        this.getMethodName = this.callAPIConstants.brandList;
        this.downloadFiles = this.callAPIConstants.downloadBrandsFile;
        this.DeleteUser = this.callAPIConstants.deleteBrands;
        this.ChangeStatus = this.callAPIConstants.changeBrandStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
      } else if (this.type === 'orderList') {
        this.getMethodName = this.callAPIConstants.orderListing;
        this.ChangeStatus = this.callAPIConstants.changeOrderStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadOrderFile;
      } else if (this.type === 'transactionList') {
        this.getMethodName = this.callAPIConstants.transactionsListing;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadOrderFile;
      } else if (this.type === 'shipmentList') {
        this.getMethodName = this.callAPIConstants.shipmentsListing;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadShipmentsFile;
      } else if (this.type === 'returnList') {
        this.getMethodName = this.callAPIConstants.returnsListing;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadCreditMemosFile;
      } else if (this.type === 'CountriesListing') {
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.getMethodName = this.callAPIConstants.CountriesListing;
        this.DeleteUser = this.callAPIConstants.DeleteCountries;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.downloadFiles = this.callAPIConstants.DownloadCountriesFile;  
        this.ChangeStatus = this.callAPIConstants.ChangeCountriesStatus;
      }else if (this.type === 'TimezoneListing') {
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.DeleteUser = this.callAPIConstants.DeleteTimezones;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getMethodName = this.callAPIConstants.TimezoneListing;
        this.downloadFiles = this.callAPIConstants.DdownloadTimezonesFile;  
        this.ChangeStatus = this.callAPIConstants.ChangeTimezoneStatus;
      }else if (this.type === 'CurrencyListing') {
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.getMethodName = this.callAPIConstants.CurrencyListing;
        this.DeleteUser = this.callAPIConstants.DeleteCurrencies;
        this.colVisible = this.callAPIConstants.ColumnSettings; 
        this.downloadFiles = this.callAPIConstants.DownloadCurrenciesFile;  
        this.ChangeStatus = this.callAPIConstants.ChangeCurrenciesStatus;
      }else if (this.type === 'ProductReviewList' || this.type === 'DriverReviewList') {
        this.getMethodName = this.callAPIConstants.ReviewListing;
        this.APIparameters['reviewType'] = (this.type === 'DriverReviewList') ? 'driver' : 'product';
        this.ChangeStatus = this.callAPIConstants.changeReviewStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadReviewsFile;
        this.DeleteUser = this.callAPIConstants.deleteReviews;
      }else if(this.type === 'promocodeOrderList'){
        this.getMethodName = this.callAPIConstants.PromoCodeListing;
      }else if (this.type === 'promoCodeList') {
        this.getMethodName = this.callAPIConstants.PromoCodeListing;
        this.ChangeStatus = this.callAPIConstants.changePromoCodeStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadPromoCodeFile;
        this.DeleteUser = this.callAPIConstants.deletePromoCode;
      }else if (this.type === 'vendorProductList') {
        this.getMethodName = this.callAPIConstants.vendorProductListing;
        this.ChangeStatus = this.callAPIConstants.ProductChangeStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadvendorProductsFile;
        this.DeleteUser = this.callAPIConstants.deleteVendorProducts;
      }else if (this.type === 'measurementsList') {
        this.getMethodName = this.callAPIConstants.measurementsListing;
        this.ChangeStatus = this.callAPIConstants.changeMeasurementsStatus;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadMeasurementFile;
        this.DeleteUser = this.callAPIConstants.deleteMeasurements;
      }else if (this.type === 'payOutsList') {
        this.getMethodName = this.callAPIConstants.vendorsPayoutsList;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadPayOutFile;
      }else if (this.type === 'vendorPayOutsList') {
        this.getMethodName = this.callAPIConstants.vendorOrdersPayoutsList;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadPayOutFile;
      }else if (this.type === 'agentPayOutsList') {
        this.getMethodName = this.callAPIConstants.getAgentsPayoutsList;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadPayOutFileForAgent;
      }else if (this.type === 'driversPayOutsList') {
        this.getMethodName = this.callAPIConstants.getDriversPayoutsList;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadPayOutFileForDriver;
      }else if (this.type === 'faqList') {
        this.getMethodName = this.callAPIConstants.faqList;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.downloadFiles = this.callAPIConstants.downloadFAQSFile;
        this.ChangeStatus = this.callAPIConstants.changeFAQSStatus;
        this.DeleteUser = this.callAPIConstants.deleteFAQS;
      }else if (this.type === 'payoutDetails') {
        this.getMethodName = this.callAPIConstants.getCustomerOrderDetails;
        this.APIparameters['vendorId'] = this.params.statusParams;
      } else if (this.type === 'payoutAgentDetails') {
        this.getMethodName = this.callAPIConstants.getCustomerOrderDetailsForAgent;
        this.APIparameters['agentId'] = this.params.statusParams;
      } else if (this.type === 'payoutDriverDetails') {
        this.getMethodName = this.callAPIConstants.getCustomerOrderDetailsForDriver;
        this.APIparameters['driverId'] = this.params.statusParams;
      }else if (this.type === 'notificationList') {
        this.getMethodName = this.callAPIConstants.getNotifications;
      } else if(this.type==='agentOrderListing'){
       this.getMethodName= this.callAPIConstants.agentOrderListing;
       this.APIparameters.agentId=this.tableSetupData.agentId;
      } else if(this.type ==='vendorProducts'){
        this.getMethodName=this.callAPIConstants.vendorSpecificProducts;
        this.APIparameters.adminId=this.params.statusParams;
      } else if(this.type ==='vendorOrders'){
        this.getMethodName=this.callAPIConstants.vendorSpecificOrders;
        this.APIparameters.adminId=this.params.statusParams;
      } else if(this.type ==='ticketList'){
        this.getMethodName=this.callAPIConstants.getTicketsListing;
        this.colVisible = this.callAPIConstants.ColumnSettings;
        this.getFilter = this.callAPIConstants.GetFilter;
        this.saveFilter = this.callAPIConstants.SaveFilter;
        this.deleteFilter = this.callAPIConstants.DeleteFilter;
        this.ChangeStatus = this.callAPIConstants.ticketChangeStatus;
        this.download=this.callAPIConstants.downloadTickets;
      } else if(this.type ==='driverOrders'){
        this.getMethodName=this.callAPIConstants.driverOrderListingForAdmin;
        this.APIparameters.driverId=this.params.statusParams;
      }
    }
    if (this.getMethodName) {
      await this.commonService.callApi(this.getMethodName, this.APIparameters, 'post' , false, false).then(async (success) => {
        if (success.status === 1) {
          this.CallAPIgetSavedFilter();
          if(this.type !== 'promocodeOrderList'){
            this.tableData.data = (this.type === 'payoutDetails' || this.type==='payoutAgentDetails' || this.type==='payoutDriverDetails') ? await success.data : await success.data.listing;
        this.tableData.data.forEach(element=>{
          if(element.currency){
            element.currencySymbol=countryCurrencyMap.getCurrency(element.currency).symbolFormat.split('{#}')[0];
          }
          if(this.type ==='driverListing'){
            element.agentId=element.agentId?element.agentId:'Admin';
          }
        })
          }
          if(this.type === 'promocodeOrderList'){
            this.commonService.callApi(this.callAPIConstants.getPromoCodeDetails + this.tableSetupData.promoCodeId, '', 'get', false, false).then((success) => {
              if (success.status === 1) { 
                this.tableSetupData.data = success.data.orderDetails;
              }
            });          }
          this.tableData.data.forEach(ele=>{
            if(ele.countryId && ele.countryId._id){
              ele.countryName=ele.countryId.countryName;
            }
          })
          this.tableConfig.cols.forEach(async (element) => {
            if (success.data.columnSettings) {
              await success.data.columnSettings.forEach((dataApi) => {
                if (element.colFieldname === dataApi.key) {
                  element.isVisible = dataApi.status;
                }
              });
            }
          });
          if (this.accessPermission && !this.accessPermission.delete) {
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'switch');
            if (this.tableConfig.cols[index]) {
              this.tableConfig.cols[index].isVisible = await false;
              this.tableConfig.cols[index].filter = await false;
              if (this.tableConfig.cols[index].columnVisibility) { this.tableConfig.cols[index].columnVisibility = await false; }
            }
          }
          this.total = success.total?success.total:this.tableData.data.length;
          this.rerenderDataTable();
        } else {
          this.showErrorService.popToast('error', 'Something Went Wrong!');
        }
      });
    }
  }
  // *************************************************************//

  /********************************************************************************
  @Purpose :  Api for downloading CSV or Excel file of datatable( with and without filter )
  /********************************************************************************/
  public download: any = {};
  public isFilter: boolean;
  CallAPIdownloadCSVandExcel(type, isFilter, url) {
    const downloadArr: any[] = [];
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility && element.isVisible) {
        downloadArr.push(element.colFieldname);
      }
    });
    isFilter ? this.download = { type: type, filteredFields: downloadArr, filter: this.filter } :
      this.download = { type: type, filteredFields: downloadArr };
      if(this.tableSetupData.reviewType){
        this.download.reviewType=this.tableSetupData.reviewType;
      }
    if (this.type === 'vendorList') { this.download['role'] = 'Vendor'; }
    if (this.type === 'categorylist') { this.download.category = this.tableConfig.pageType; }
    if (this.type === 'subCategorylist') {
      this.download.category = this.tableConfig.pageType;
      this.download.parentCategory = this.localStorageService.getToken('parentCategoryId');
    }
    if (this.type === 'subSubCategorylist') {
      this.download.category = this.tableConfig.pageType;
      this.download.category = this.tableConfig.pageType;
      this.download.parentCategory = this.localStorageService.getToken('parentSubCategoryId');
    }
    this.commonService.callApi(this.downloadFiles, this.download, 'post', false, false).then((success) => {
      window.open([url] + success.data.filePathAndName, '_blank');
    });
  }
  // *************************************************************//

  /*************************************************************
    @Purpose : select users and change status
  /*************************************************************/
  public statusFormData = { status: '' };
  public statusList: any[];

  selectMultipleUser(data, type?) {
    if (data.checkbox) {
      if (this.selectedUserList.length) {
        const x = this.selectedUserList.findIndex((o) => o === data._id);
        if (x === -1) {
          this.selectedUserList.push(data._id);
        }
      } else {
        this.selectedUserList.push(data._id);
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.checkbox = false;
      });
      const x = this.selectedUserList.findIndex((o) => o === data._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    }
  }

  // tslint:disable-next-line: cognitive-complexity
  submitStatusForm(statusForm, statusFormData) {
    this.submitted = true;
    if (statusForm.valid && this.selectedUserList.length) {
      // DELETE
      if (statusFormData.status === 'Delete') {
        this.showErrorService.confirmpopToast('warning', 'Do you want to delete ?', 'DELETE').then((result) => {
          if (result.value === true) {
            const data = { [this.params.deleteParams]: this.selectedUserList };
            this.CallAPIchangeStatusORDelete(this.DeleteUser, data);
          } else {
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
            this.tableConfig.cols[index].checkbox = false;
            this.deselectMultipleUser();
          }
        });
      }
      // DELETE
      // STATUS
      
      if (statusFormData.status === 'Inactive' || statusFormData.status === 'Active' ||
        statusFormData.status === 'Published' || statusFormData.status === 'UnPublished') {
        let data;
        if (this.type === 'categorylist' || this.type === 'productList' || this.type === 'vendorProductList') {
          statusFormData.status === 'Inactive' || statusFormData.status === 'UnPublished' ?
            data = { [this.params.statusParams]: this.selectedUserList, publish: false } :
            data = { [this.params.statusParams]: this.selectedUserList, publish: true };
        }  else {
          statusFormData.status === 'Inactive' || statusFormData.status === 'UnPublished' ?
            data = { [this.params.statusParams]: this.selectedUserList, status: false } :
            data = { [this.params.statusParams]: this.selectedUserList, status: true };
        }
        this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
        const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
        this.tableConfig.cols[index].checkbox = false;
      } else if(this.type ==='ticketList' && statusFormData.status === 'InProgress' || statusFormData.status === 'Resolved' ||statusFormData.status ==='Closed' || statusFormData.status ==='Open' ){
        let data:any;
            data = { [this.params.statusParams]: this.selectedUserList, ticketStatus: statusFormData.status }
            this.CallAPIchangeStatusORDelete(this.ChangeStatus, data);
            const index = this.tableConfig.cols.findIndex((o) => o.type === 'multipleSelection');
            this.tableConfig.cols[index].checkbox = false;
          }
      // STATUS
    }
    statusForm.reset();
  }
  // *************************************************************//

  /***************************************************************
    @Purpose : Pagination
  /***************************************************************/
  public itemsPerPageList = [2, 5, 10, 25, 100];
  itemsPerPage = 10;

  pagination(e) {
    this.deselectMultipleUser();
    this.APIparameters.page = e.page;
    this.itemsPerPage = e.itemsPerPage;
    this.callApiToGetData();
    // setTimeout(() => {
    //   if (this.selectedUserList.length) {
    //     this.selectedUserList.forEach((element) => {
    //       const x = this.tableData.data.findIndex((o) => element === o._id);
    //       if (x > -1) {
    //         this.tableData.data[x].checkbox = true;
    //       }
    //     });
    //   }
    // }, 500);
  }

  selectItemPerPage(e) {
    this.customPagination._page = 1;
    this.APIparameters.pagesize = e;
    this.APIparameters.page = 1;
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
    @Purpose : change visibility of columns
  /***************************************************************/

  public columnVisibleArr: any[] = [];

  tooglecolumnVisibilityFilter() {
    this.columnVisibilityOptions = !this.columnVisibilityOptions;
    this.filterColumnOption = false;
  }

  changeCheckBoxValue() {
    let count = 0;
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility === true && element.isVisible === true) { count++; }
    });
    if (count === 1) {
      const index = this.tableConfig.cols.findIndex((o) => o.isVisible === true && o.columnVisibility === true);
      if (index > -1 && this.tableConfig.cols[index]) {
        this.tableConfig.cols[index].disable = true;
      }
    } else {
      this.tableConfig.cols.forEach((element) => {
        element.disable = false;
      });
    }
  }

  CallAPIchangeColumnVisibility(tableConfigCols) {
    tableConfigCols.forEach((element) => {
      if (element.columnVisibility) {
        this.columnVisibleArr.push({ key: element.colFieldname, status: element.isVisible });
      }
    });
    let data;
    if (this.tableConfig.pageType) { data = { columns: this.columnVisibleArr, key: this.type, type: this.tableConfig.pageType }; } else {
      data = { columns: this.columnVisibleArr, key: this.type };
    }
    this.commonService.callApi(this.colVisible, data, 'post', false, false).then((success) => {
      success.status === 1 ?
        this.showErrorService.popToast('success', 'Updated Successfully') : this.showErrorService.popToast('error', success.message);
    });
    this.columnVisibleArr = [];
    this.callApiToGetData();
  }

  selectAllColumnVisible() {
    this.tableConfig.cols.forEach((element) => {
      if (element.columnVisibility) { element.isVisible = true; }
    });
  }

  resetColumnVisible() {
    this.callApiToGetData();
  }
  /***************************************************************/

  /*************************************************************
  @Purpose    : Api for change Status and Delete
  @parameters : callAPi ==> name of Api to be called
                data ==> data to be send
  /*************************************************************/
  CallAPIchangeStatusORDelete(callAPI, data) {
    this.commonService.callApi(callAPI, data, 'post', false, false).then((success) => {
      if (success.status === 1) {
          this.showErrorService.popToast('success', success.message);
        setTimeout(() => {
          this.callApiToGetData();
        });
        this.selectedUserList = [];
      } else {
        this.showErrorService.popToast('error', 'Something Went Wrong!');
      }
    });
  }

  createPayout(data){
    this.commonService.callApi(this.callAPIConstants.createPayoutForVendor, data, 'post', false, false).then((success) => {
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        setTimeout(() => {
          this.callApiToGetData();
        });
        this.selectedUserList = [];
      } else {
        this.showErrorService.popToast('error', 'Something Went Wrong!');
      }
    });
  }

  // *************************************************************//

  /*************************************************************
    @Purpose :  Rerendering Datatable
  /*************************************************************/
  async rerenderDataTable() {
    if (this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then(async (dtInstance: DataTables.Api) => {
        // Destroy the table first
        await dtInstance.destroy();
        // Call the dtTrigger to rerender again
        await this.dtTrigger.next();
      });
    } else { await this.dtTrigger.next(); }
  }
  // *************************************************************//

  /*************************************************************
    @Purpose : Sorting for user listing
  /*************************************************************/

  onSorted(event) {
    this.APIparameters.sort = {};
    event.sortDirection === 'desc' ? event.sortDirection = -1 : event.sortDirection = 1;
    this.APIparameters.sort[event.sortColumn.colFieldname] = event.sortDirection;
    this.callApiToGetData();
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Perform Action: VIEW, EDIT, DELETE And SWITCH
  /***************************************************************/

  performAction(k, i) {
    // VIEW action
    if (k.id === 1) {
      if (this.type === 'userlist' || this.type === 'newUsersList') {
        this.router.navigate([this.URLConstants.CUSTOMER_DETAILS, i._id]);
      } else if (this.type === 'brandlist') {
        this.router.navigate([this.URLConstants.BRAND_DETAIL, i._id]);
      } else if (this.type === 'orderList' || this.type === 'transactionList' || this.type === 'ordersList'
        || this.type === 'userOrderList' || this.type==='vendorOrders' || this.type==='driverOrders') {
        this.router.navigate([this.URLConstants.ORDER_DETAIL, i._id]);
      } else if (this.type === 'driverListing') {
        this.router.navigate([this.URLConstants.DRIVER_VIEW_DETAILS, i._id]);
      } else if (this.type === 'vendorList') {
        this.router.navigate([this.URLConstants.VIEW_VENDOR, i._id]);
      } else if (this.type === 'returnList') {
        this.router.navigate([this.URLConstants.RETURN_DETAIL, i._id]);
      } else if (this.type === 'shipmentList') {
        this.router.navigate([this.URLConstants.SHIPMENT_DETAIL, i._id]);
      }else if (this.type === 'productList' || this.type==='vendorProducts' ) {
        this.router.navigate([this.URLConstants.VIEW_PRODUCT, i._id]);
      }else if (this.type === 'vendorProductList') {
        this.router.navigate([this.URLConstants.VIEW_PRODUCT, i.vendorProductId]);
      }else if (this.type === 'promoCodeList') {
        this.router.navigate([this.URLConstants.VIEW_PROMOCODE, i._id]);
      }else if (this.type === 'promocodeOrderList' || this.type==='agentOrderListing') {
        this.router.navigate([this.URLConstants.ORDER_DETAIL, i._id]);
      }else if (this.type === 'cmslist') {
        this.router.navigate([this.URLConstants.VIEW_CMS, i._id]);
      }else if (this.type === 'payOutsList' || this.type === 'vendorPayOutsList') {
        this.router.navigate([this.URLConstants.VIEW_PAYOUTS, i._id]);
      } else if (this.type === 'agentListing') {
        this.router.navigate([this.URLConstants.AGENTUSER_DETAILS, i._id]);
      }  else if (this.type === 'agentPayOutsList') {
        this.router.navigate([this.URLConstants.VIEW_PAYOUTS_AGENT, i._id]);
      }
        else if (this.type === 'driversPayOutsList') {
        this.router.navigate([this.URLConstants.VIEW_PAYOUTS_DRIVER, i._id]);
      }
    } else if (k.id === 2) {
      if (this.type === 'adminUserlist') {
        this.router.navigate([this.URLConstants.ADMINUSER_DETAIL, i._id]);
      } else if(this.type==='agentListing') {
        this.router.navigate([this.URLConstants.AGENTUSER_EDIT, i._id]);
      } else if (this.type === 'emailTemplateList') {
        this.router.navigate([this.URLConstants.EMAIL_TEMPLATE_detail, i._id]);
      } else if (this.type === 'cmslist') {
        this.router.navigate([this.URLConstants.CMS_DETAIL, i._id]);
      } else if (this.type === 'roleList') {
        this.router.navigate([this.URLConstants.MANAGE_ROLES_DETAIL, i._id]);
      } else if (this.type === 'categorylist') {
        this.router.navigate([this.URLConstants.CATEGORY_DETAIL, i._id]);
      } else if (this.type === 'subCategorylist') {
        this.router.navigate([this.URLConstants.SUB_CATEGORY_DETAIL, i._id]);
      } else if (this.type === 'subSubCategorylist') {
        this.router.navigate([this.URLConstants.SUB_SUB_CATEGORY_DETAIL, i._id]);
      } else if (this.type === 'productList' || this.type === 'mostSeenList' || this.type === 'vendorProductList') {
        this.router.navigate([this.URLConstants.PRODUCTS_DETAIL, i._id]);
      }else if (this.type === 'brandlist') {
        this.router.navigate([this.URLConstants.EDITBRAND, i._id]);
      } else if (this.type === 'statelist') {
        this.router.navigate([this.URLConstants.EDITSTATE, i._id]);
      } else if (this.type === 'vendorList') {
        this.router.navigate([this.URLConstants.VENDOR_DETAIL, i._id]);
      } else if (this.type === 'driverListing') {
        this.router.navigate([this.URLConstants.DRIVER_DETAIL, i._id]);
      } else if (this.type === 'bestSellerList') {
        this.router.navigate([this.URLConstants.PRODUCTS_DETAIL, i.product_Id]);
      } else if (this.type === 'CountriesListing') {
        this.openMasterModal('edit',i)
      } else if (this.type === 'TimezoneListing') {
        this.openMasterModal('edit',i)
      } else if (this.type === 'CurrencyListing') {
        this.openMasterModal('edit',i)
      } else if (this.type === 'measurementsList') {
        this.openMasterModal('edit',i)
      } else if (this.type === 'ProductReviewList' || this.type === 'VendorReviewList') {
        this.router.navigate([this.URLConstants.EDIT_RATING, i._id]);
      } else if (this.type === 'promoCodeList') {
        this.router.navigate([this.URLConstants.EDIT_PROMOCODE, i._id]);
      } else if (this.type === 'faqList') {
        this.openMasterModal('edit',i)
      } 
    } else if (k.id === 3) {
      this.showErrorService.confirmpopToast('warning', 'Do you want to delete ?', 'DELETE').then((result) => {
        if (result.value === true) {
          const dataObj = {
            [this.params.deleteParams]: [i._id],
          };
          if (this.type === 'vendorProductList') { dataObj[this.params.deleteParams] = [i.vendorProductId]; }
          if (this.type === 'relatedItemsList') { dataObj['productId'] = this.params.statusParams; }
          this.CallAPIchangeStatusORDelete(this.DeleteUser, dataObj);
        }
      });
    }else if (k.id === 4) { //Generate Payout
      this.openMasterModal('edit',i)
    }
  }
  // SWITCH action
  changeStatus(d) {
    const index = this.tableConfig.cols.findIndex(o => o.type === 'multipleSelection');
    if (this.tableConfig.cols[index]) { this.tableConfig.cols[index].checkbox = false; }
    setTimeout(() => {
      let dataObj;
      if (this.type === 'categorylist' || this.type === 'subCategorylist' || this.type === 'subSubCategorylist' || this.type === 'productList') {
        dataObj = { [this.params.statusParams]: [d._id], publish: d.publish };
      } else if(this.type ==='ticketList'){
        dataObj = { [this.params.statusParams]: [d._id], type: d.status };
      } else {
        dataObj = { [this.params.statusParams]: [d._id], status: d.status };
      }
      this.CallAPIchangeStatusORDelete(this.ChangeStatus, dataObj);
    });
  }

  /***************************************************************/

  /***************************************************************
    @Purpose :  SELECT ALL USERS IN TABLE
  /***************************************************************/
  selectAllUser(i) {
    if (i.checkbox === true) {
      this.tableData.data.forEach((element) => {
        element.checkbox = true;
        if (this.selectedUserList.length) {
          const x = this.selectedUserList.findIndex((o) => o === element._id);
          if (x === -1) {
            this.selectedUserList.push(element._id);
          }
        } else {
          this.selectedUserList.push(element._id);
        }
      });
    } else {
      this.deselectMultipleUser();
    }
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  Common Function
  /***************************************************************/
  deselectMultipleUser() {
    this.tableConfig.cols.forEach((element) => {
      element.checkbox = false;
    });
    this.tableData.data.forEach((element) => {
      element.checkbox = false;
      const x = this.selectedUserList.findIndex((o) => o === element._id);
      if (x > -1) {
        this.selectedUserList.splice(x, 1);
      }
    });
  }

  modifyFilterArr(fieldName, event) {
    const index = this.filter.findIndex((o) => Object.keys(o).includes(fieldName));
    if (index > -1) { this.filter[index][fieldName] = event; } else {
      this.filter.push({ [fieldName]: event });
    }
  }
  /***************************************************************/

  /***************************************************************
    @Purpose :  filter
  /***************************************************************/
  statusFilterlist: any[] = [];
  public filter = [];
  public disableApplyFilter = true;
  public disableResetFilter = true;

  toggleFilterColumn() {
    this.filterColumnOption = !this.filterColumnOption;
    this.columnVisibilityOptions = false;
  }

  getSatutsFilterlistData(colName) {
    if (colName === 'status') {
      this.statusFilterlist = ['Active', 'Inactive'];
    } else if (colName === 'publish') {
      this.statusFilterlist = ['Published', 'UnPublished'];
    } else if (colName === 'orderStatus') {
      this.statusFilterlist = ['pending', 'processing', 'delivered', 'closed', 'cancelled', 'refunded', 'hold', 'unHold'];
    } else if (colName === 'isOnline') {
      this.statusFilterlist = ['Online', 'Offline'];
    } else if (colName === 'discountType') {
      this.statusFilterlist = ['FLAT', 'Percentage'];
    } else if (colName==='countryName'){

    }
  }

  selectFilterStatus(fieldName, event) {
    const index = this.tableConfig.cols.findIndex((o) => {
      if (o.value) { return o.value.length; }
    });
    index > -1 ? this.disableApplyFilter = false : this.disableApplyFilter = true;
    if (fieldName === 'status' || fieldName === 'publish' || fieldName === 'isOnline') {
      // tslint:disable-next-line: triple-equals
      if (event == 'Active' || event == 'Published' || event == 'Online') {
        this.modifyFilterArr(fieldName, [true]);
        // tslint:disable-next-line: triple-equals
      } else if (event == 'Inactive' || event == 'UnPublished' || event == 'Offline') {
        this.modifyFilterArr(fieldName, [false]);
        // tslint:disable-next-line: triple-equals
      } else if (event == '') {
        this.modifyFilterArr(fieldName, []);
      } else {
        this.modifyFilterArr(fieldName, [true, false]);
      }
    } else {
      this.modifyFilterArr(fieldName, event);
    }
  }

  getSelectedFilterValueFromTyeAhead(data) {
    const index1 = this.tableConfig.cols.findIndex((o) => {
      if (o.value) { return o.value.length; }
    });
    index1 > -1 ? this.disableApplyFilter = false : this.disableApplyFilter = true;
    if (data.fieldName === 'addedBy') { this.APIparameters.addedBy = data.value; } else {
      const index = this.filter.findIndex((o) => Object.keys(o).includes(data.fieldName));
      if (index > -1) { this.filter[index][data.fieldName] = data.value; } else {
        this.filter.push({ [data.fieldName]: data.value });
      }
    }
  }

  applyFilter() {
    // if (this.filter.length) {
    this.disableResetFilter = false;
    this.APIparameters.filter = this.filter;
    
    this.callApiToGetData();
    // }
  }

  resetFilter() {
    this.disableApplyFilter = true;
    this.disableResetFilter = true;
    this.filter = [];
    this.tableConfig.cols.forEach((element) => {
      element.value = [];
      delete element.min;
      delete element.max;
      delete element.validity;
    });
    this.APIparameters.filter = [];
    this.formDateRangepicker = [];
    this.callApiToGetData();

  }
  /***************************************************************/

  /***************************************************************
    @Purpose : SAVE and DELETE FILTER
  /***************************************************************/

  openSaveFilterModal(saveFilters: TemplateRef<any>) {
    this.BsModalRef = this.modalService.show(saveFilters, { class: 'modal-dialog-centered' });
  }

  public filterToSave = { filterName: '',reason:'' };
  submitSaveFilter(saveFilterForm, filterToSave) {
    this.submitted = true;
    if (saveFilterForm.valid && this.filter) {
      this.tableConfig.cols.forEach((element) => {
        element.value = [];
      });
      const filter = {
        filterName: filterToSave.filterName,
        filter: this.filter,
        key: this.type,
      };
      if (this.type === 'subCategorylist') {
        filter['parentCategory'] = this.localStorageService.getToken('parentCategoryId');
      } else if (this.type === 'subSubCategorylist') {
        filter['parentCategory'] = this.localStorageService.getToken('parentSubCategoryId');
      }
      this.filter = [];
      this.commonService.callApi(this.saveFilter, filter, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.disableApplyFilter = true;
          this.showErrorService.popToast('success', 'Filter is saved Successfully');
          this.CallAPIgetSavedFilter();
        }
      });
      this.submitted = false;
      this.BsModalRef.hide();
    }
    saveFilterForm.reset();
  }

  public dataOfSaveFilter: any;
  CallAPIgetSavedFilter() {
    if (this.getFilter) {
      const data = { key: this.type };
      if (this.type === 'subCategorylist') {
        data['parentCategory'] = this.localStorageService.getToken('parentCategoryId');
      } else if (this.type === 'subSubCategorylist') {
        data['parentCategory'] = this.localStorageService.getToken('parentSubCategoryId');
      }
      this.commonService.callApi(this.getFilter, data, 'post', false, false).then((success) => {
        if (success.status === 1) {
          this.dataOfSaveFilter = success.data;
        }
      });
    }
  }

  showSaveFilter(i) {
    this.disableResetFilter = false;
    this.APIparameters.filter = i.filter;
    this.callApiToGetData();
  }

  CallAPIdeleteSaveFilter(i) {
    this.disableResetFilter = true;
    this.commonService.callApi(this.deleteFilter + i._id, '', 'delete', false, false).then((success) => {
      if (success.status === 1) {
        this.showErrorService.popToast('success', success.message);
        this.dataOfSaveFilter = {};
      } else { this.showErrorService.popToast('error', success.message); }
    });
    this.CallAPIgetSavedFilter();
  }

  dateCreated(fieldName, $event,i) {
    if ($event != undefined && $event.length) {
      let startDate = ('0' + ($event[0].getMonth() + 1)).slice(-2) + '/' + ('0' + $event[0].getDate()).slice(-2) + '/' + $event[0].getFullYear();
      let endDate = ('0' + ($event[1].getMonth() + 1)).slice(-2) + '/' + ('0' + $event[1].getDate()).slice(-2) + '/' + $event[1].getFullYear();
      let dateFilterData = { 'startDate': startDate, 'endDate': endDate }
      const check_index = this.filter.findIndex((o) => Object.keys(o).includes(fieldName));
      if (check_index > -1) { this.filter[check_index][fieldName] = dateFilterData; } else {
        this.filter.push({ [fieldName]: dateFilterData });
      }
      this.disableApplyFilter = false;
      this.disableResetFilter = false;
    }
  }
  minMaxCreated(fieldName, i) {
    if(i.min && i.max){
      let dateFilterData = { 'min':Number(i.min), 'max': Number(i.max) };
      const check_index = this.filter.findIndex((o) => Object.keys(o).includes(fieldName));
      if (check_index > -1) { this.filter[check_index][fieldName] = dateFilterData; } else {
        this.filter.push({ [fieldName]: dateFilterData });
      }
      this.disableApplyFilter = false;
      this.disableResetFilter = false;
    }
     
  }
  /***************************************************************/

  public langFormData = { language: this.localStorageService.getToken('language') };
  changeLanguage(form,langFormData){
    this.localStorageService.setToken('language',langFormData.language);
    this.callApiToGetData();
  }

   /***************************************************************
    @Purpose : OPEN COUNTRY MODAL
  /***************************************************************/
  openMasterModal(e,i){  
    if(e === 'edit'){
     const Data =  Object.assign({},i)

      this.BsModalRef = this.modalService.show(GeneralMasterModalComponent, {
        initialState: {
          title: 'Edit',
          data: Data,
          type: this.type
      },
      class: 'modal-dialog-centered'
      });
    }
    else{
      this.BsModalRef = this.modalService.show(GeneralMasterModalComponent, {
        initialState: {
          title: 'Add',
          data: {},
          type: this.type

      },
      class: 'modal-dialog-centered'
      });
    }
    }
 // *************************************************************//

    /***************************************************************
    @Purpose : OPEN TIMEZONE MODAL
  /***************************************************************/
  // openModalTimezone(e,i){ 
  //   if(e === 'edit'){
  //     const Data =  Object.assign({},i)

  //     this.BsModalRef = this.modalService.show(GeneralMasterModalComponent, {
  //       initialState: {
  //         title: 'Edit Timezone',
  //         data: Data,
  //         type: this.type
  //     },
  //     class: 'modal-dialog-centered'
  //     });
  //   }
  //   else{ 
  //     this.BsModalRef = this.modalService.show(GeneralMasterModalComponent, {
  //       initialState: {
  //         title: 'Add Timezone',
  //         data: {},
  //         type: this.type
  //     },
  //     class: 'modal-dialog-centered'
  //     });
  //   }
  //   }
 // *************************************************************//

   /***************************************************************
    @Purpose : OPEN CURRENCY MODAL
  /***************************************************************/
  // openModalCurrency(e,i){   
  //   if(e === 'edit'){
  //     const Data =  Object.assign({},i)
  //     this.BsModalRef = this.modalService.show(GeneralMasterModalComponent, {
  //       initialState: {
  //         title: 'Edit Currency',
  //         data: Data,
  //         type: this.type
  //     },
  //     class: 'modal-dialog-centered'
  //     });
  //   }
  //   else{
  //     this.BsModalRef = this.modalService.show(GeneralMasterModalComponent, {
  //       initialState: {
  //         title: 'Add Currency',
  //         data: {},
  //         type: this.type
  //     },
  //     class: 'modal-dialog-centered'
  //     });
  //   }
  //   this
  //   }
 // *************************************************************//

  /***************************************************************
    @Purpose : Modal Close Function
  /***************************************************************/
  bsModalRefclose() {
    this.BsModalRef.hide();
    this.country.countryName = null;
    this.country.timezone = null;
  }
  /***************************************************************/
  public reason:any={};
  changeApprovalStatus(i,event){
    if(event.name !=='Rejected'){
      let apiName=this.type=='vendorList'?this.callAPIConstants.changeAdminApprovalStatus : this.type==='agentListing'?this.callAPIConstants.changeAgentApprovalStatus:this.callAPIConstants.changeDriverApprovalStatus;
      let apiData:any={};
      apiData.approvalStatus=i.approvalStatus;
      apiData[this.params.statusParams]=[i._id];
      apiData.rejectionReason='';
      this.commonService.callApi(apiName,apiData,'post').then(res=>{
        if(res.status === 1){
          this.showErrorService.popToast('success',res.message);
          this.callApiToGetData();
        } else{
          this.showErrorService.popToast('error',res.message);
        }
      })
    } else{
this.i=i;
this.BsModalRef = this.modalService.show(GeneralMasterModalComponent, {
  initialState: {
    title: 'Edit',
    data: i,
    statusParams:this.params.statusParams,
    type: this.type
},
class: 'modal-dialog-centered'
});
    }
  }
  changeTicketStatus(i,event){
    let apiData:any={};
    apiData.ticketStatus=i.ticketStatus;
    apiData[this.params.statusParams]=[i._id];
    this.commonService.callApi(this.callAPIConstants.ticketChangeStatus,apiData,'post').then(res=>{
      if(res.status === 1){
        this.showErrorService.popToast('success',res.message);
        this.callApiToGetData();
      } else{
        this.showErrorService.popToast('error',res.message);
      }
    })
  }
  submitReason(){
    let apiName=this.type=='vendorList'?this.callAPIConstants.changeAdminApprovalStatus : this.type==='agentListing'?this.callAPIConstants.changeAgentApprovalStatus:this.callAPIConstants.changeDriverApprovalStatus;
    let apiData:any={};
    apiData.approvalStatus='rejected';
    apiData[this.params.statusParams]=[this.i._id];
    apiData.reasom=this.filterToSave.reason;
    this.commonService.callApi(apiName,apiData,'post').then(res=>{
      if(res.status === 1){
        this.showErrorService.popToast('success',res.message);
        this.callApiToGetData();
        this.BsModalRef.hide();
      } else{
        this.showErrorService.popToast('error',res.message);
      }
    })
  }
  changeAgent(i,event){
    if(event._id ==='Admin'){

    }else{
this.commonService.callApi(this.callAPIConstants.assignDriverUnderAgent,{agentId:event._id,driverId:i._id},'post').then(res=>{
  if(res.status === 1){
    this.showErrorService.popToast('success',res.message);
    this.callApiToGetData();
  } else{
    this.showErrorService.popToast('error',res.message);
  }
})
    }

  }
}
